import { Capacitor } from '@capacitor/core'
import { useIonToast } from '@ionic/react'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { RUTAS } from '../constant'
import { useLogoutDevicePost, useUsuario } from '../models/query-hooks'
import { useStores } from '../models/root-store/root-store-context'

export const formato_puntos = (monto: number) => {
  return numeral(monto).format('0,00 $')
}

export type DetailViewProps = {
  history: any
  match: any
  router: any
}

export const useLogout = () => {
  const { loginStore } = useStores()
  const history = useHistory()
  const logoutDevice = useLogoutDevicePost()

  return () => {
    logoutDevice.mutate()
    loginStore.logout()
    history.push(RUTAS.LOGIN)
  }
}

export const useIsLogin = () => {
  const { loginStore } = useStores()

  return !!loginStore.usuario
}

export const useRedirectLogin = () => {
  const { loginStore } = useStores()
  const history = useHistory()

  return () => {
    if (!loginStore.usuario) history.push(RUTAS.LOGIN)
  }
}

export const useIsMovil = () => {
  return Capacitor.getPlatform() !== 'web' || window.innerWidth <= 500 || /Mobi/.test(navigator.userAgent)
}

export const useStoreUser = () => {
  const rootStore = useStores()
  const { loginStore } = rootStore
  const { usuario } = loginStore

  return usuario
}

export const useUpdateStoreUser = () => {
  const user = useStoreUser()
  const { loginStore } = useStores()
  const { data: usuario } = useUsuario(user?.id)

  useEffect(() => {
    if (!!usuario) {
      loginStore.setUsuario(usuario)
    }
  }, [usuario])

  return usuario
}

export const useSearchQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const useToaster = () => {
  const [present, dismiss] = useIonToast()

  type TostType = 'success' | 'error' | 'danger' | 'dark'

  return (msg = 'La acción se completó exitosamente', tipo: TostType = 'dark', autoDismiss = true) => {
    if (tipo === 'error') {
      tipo = 'danger'
    }
    present({
      buttons: [{ text: '✖', handler: () => dismiss() }],
      duration: autoDismiss ? 5000 : undefined,
      message: msg,
      color: tipo,
    })
  }
}

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}
