import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { ClaseModel, ClaseSnapshot } from '../clase/clase'
import { withEnvironment } from '../extensions/with-environment'

/**
 * Clase Store
 */
export const ClaseStoreModel = types
  .model('ClaseStore')
  .extend(withEnvironment)
  .props({
    clases: types.optional(types.array(ClaseModel), []),
    claseSeleccionada: types.maybeNull(ClaseModel),
  })
  .actions((self) => ({

  }))


type ClaseStoreType = Instance<typeof ClaseStoreModel>
export interface ClaseStore extends ClaseStoreType {}
type ClaseStoreSnapshotType = SnapshotOut<typeof ClaseStoreModel>
export interface ClaseStoreSnapshot extends ClaseStoreSnapshotType {}
export const createClaseStoreDefaultModel = () => types.optional(ClaseStoreModel, {})
