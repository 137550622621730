import { IonCardHeader, IonCheckbox, IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { BsCheck, BsEye, BsEyeSlash } from 'react-icons/bs'

import { Redirect, useHistory } from 'react-router'
import { Button, ImgAsset, NavHeader, TextField } from '../../components'
import { RUTAS } from '../../constant'
import { useIsMovil } from '../../helpers'
import { useStores } from '../../models/root-store/root-store-context'
import './Login.css'
interface IError {
  password?: string
  username?: string
  non_field_errors?: string
}

export const Login: React.FC = observer(() => {
  const [Loading, setLoading] = useState(false)
  const [Password, setPasword] = useState('')
  const [Username, setUsername] = useState('')
  const [Errors, setErrors] = useState<IError>()
  const { loginStore } = useStores()
  const [MostarContrasena, setMostrarContrasena] = useState(false)
  const [Index, setIndex] = useState<'videos' | 'lanzamientos' | 'cupones' | 'login'>('videos')
  const history = useHistory()
  const isMovil = useIsMovil()

  const iniciarSesion = () => {
    const error = { ...Errors }
    setLoading(true)
    loginStore.getLogin(Username, Password).then((result) => {
      setLoading(false)
      console.log(result)
      if (result.kind !== 'ok') {
        // @ts-ignore
        setErrors(result.data)
      }
    })
  }

  if (loginStore.usuario?.token) return <Redirect to={RUTAS.HOME} />
  return (
    <IonPage className="bg-white" id="login-page">
      {Index !== 'login' && (
        <div className="relative w-full h-full">
          <div className="absolute bottom-0 w-full h-1/4 bg-gradient-to-b from-white to-yellow" />
          <div className="w-full h-full flex flex-col items-center justify-center">
            <ImgAsset selected="academiaLogo" className=" shell-logo mx-auto w-28 h-24 mb-7 mt-16" />
            <h2 className="mb-3 animate-appear">
              {Index === 'videos' && 'Videos Instructivos'}
              {Index === 'lanzamientos' && 'Nuevos Lanzamientos'}
              {Index === 'cupones' && 'Cupones'}
            </h2>
            <div className="w-80  animate-appear">
              <p className="text-center">
                {Index === 'videos' && (
                  <>
                    El aprendizaje es para todos. Ahora podes informarte de todos los productos y servicios que ofrece
                    da marca Shell.
                  </>
                )}
                {Index === 'lanzamientos' && (
                  <>
                    Creamos un espacio para novedades de la marca, como un muro donde podras informarte de lo que esta
                    por venir.
                  </>
                )}
                {Index === 'cupones' && (
                  <>Suma puntos por visualizaciones y canjea por productos que te ofrece la marca Shell!</>
                )}
              </p>
            </div>
            <div className=" flex-1 w-full relative animate-appear">
              <div className="flex justify-center w-full absolute bottom-[80%]">
                <div
                  className="w-3 h-[0.125rem] mr-3 bg-secondary"
                  style={Index === 'videos' ? { width: '1.563rem' } : {}}
                />
                <div
                  className="w-3 h-[0.125rem] mr-3 bg-secondary"
                  style={Index === 'lanzamientos' ? { width: '1.563rem' } : {}}
                />
                <div
                  className="w-3 h-[0.125rem] bg-secondary"
                  style={Index === 'cupones' ? { width: '1.563rem' } : {}}
                />
              </div>
            </div>
            <div className="px-5 w-full flex items-center flex-col justify-end pb-10 animate-appear">
              <Button
                className="w-full lg:w-[30vw]"
                onClick={() => {
                  if (Index === 'videos') {
                    setIndex('lanzamientos')
                  }
                  if (Index === 'lanzamientos') {
                    setIndex('cupones')
                  }
                  if (Index === 'cupones') {
                    setIndex('login')
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        </div>
      )}
      {Index === 'login' && (
        <>
          {isMovil && <NavHeader goBackAction={() => setIndex('cupones')} title="Iniciar Sesión" />}

          <IonContent className="w-full h-full ">
            <div className="flex justify-center pt-5">
              <ImgAsset selected="academiaLogo" className="w-16" />
            </div>
            <h1 className="text-center text-[2rem] font-bold">Bienvenido</h1>
            <h3 className="text-center my-5">Ingresa tu Usuario</h3>
            <form>
              <IonGrid className="px-4">
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12" sizeLg="3">
                    <TextField
                      value={Username}
                      onIonChange={(e) => setUsername(e.detail.value ?? '')}
                      placeholder="Ingrese su nombre de usuario"
                      label="Usuario"
                      rightIcon={<BsCheck size={20} />}
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12" sizeLg="3">
                    <TextField
                      value={Password}
                      onIonChange={(e) => setPasword(e.detail.value ?? '')}
                      placeholder="Ingrese su contraseña"
                      label={'Password'}
                      rightIcon={
                        <div className="cursor-pointer" onClick={() => setMostrarContrasena(!MostarContrasena)}>
                          {MostarContrasena ? <BsEye /> : <BsEyeSlash />}{' '}
                        </div>
                      }
                      type={MostarContrasena ? 'text' : 'password'}
                    />
                  </IonCol>
                </IonRow>
                <div className="mb-5  flex justify-center">
                  <IonCol size="12" sizeLg="3">
                    <div className="flex justify-between">
                      <div className="flex items-center ">
                        <IonCheckbox className="mr-3" />
                        <span>Recordarme</span>
                      </div>
                      <div>
                        <span className="font-bold cursor-pointer">Olvidé la contraseña?</span>
                      </div>
                    </div>
                    <div>
                      <span className="text-red-500">{Errors?.non_field_errors!}</span>
                    </div>
                  </IonCol>
                </div>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12" sizeLg="3">
                    <Button size="large" expand="block" onClick={iniciarSesion}>
                      {!Loading && <>Ingresar</>}
                      {Loading && <IonSpinner />}
                    </Button>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeLg="6">
                    {/* <div className="flex justify-center pt-3">
                        <span className="cursor-pointer text-center">
                          No dispone de una cuenta? <strong>Solicitar Cuenta.</strong>
                        </span>
                      </div> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonContent>
        </>
      )}
    </IonPage>
  )
})
