import { IonModal, IonPage } from '@ionic/react'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Button, ImgAsset, NavHeader, Page, PasswordField } from '../components'
import { RUTAS } from '../constant'
import { useActualizarContrasena } from '../models/query-hooks'
import { NuevaContrasena } from '../services/api'

export const CambioContrasenaPage: React.FC = (props) => {
  const [Success, setSuccess] = useState(false)
  const { mutate: actualizarContrasena } = useActualizarContrasena()
  const [Contrasena, setNuevaContrasena] = useState<NuevaContrasena>({
    contrasena: '',
    contrasena_anterior: '',
    repetir_contrasena: '',
  })
  const history = useHistory()

  const setValue = (key: keyof NuevaContrasena, data: string) => {
    const contrasena = { ...Contrasena }
    contrasena[key] = data
    setNuevaContrasena({ ...contrasena })
  }

  const onSubmit = () =>
    actualizarContrasena(
      { ...Contrasena },
      {
        onSuccess: () => {
          setSuccess(true)
        },
      },
    )
  return (
    <>
      <Page header={<NavHeader title="Cambiar Contraseña" />}>
        <IonModal isOpen={Success}>
          <div className=" px-5 h-full flex flex-col justify-center   items-center">
            <ImgAsset selected="academiaLogo" className="w-16 h-16 mb-36 animate-bottomAppear" />
            <h2 className="animate-appear mb-5">Tu Contraseña ya fue solucitada</h2>
            <div className="w-72 animate-appear mb-28 ">
              <p className="text-center animate-appear">El staff se pondrá en contacto contigo en su brevedad</p>
            </div>
            <Button
              className="w-full animate-appear lg:w-[30vw]"
              onClick={() => {
                setSuccess(!Success)
                history.push(RUTAS.PERFIL)
              }}
            >
              Finalizar
            </Button>
          </div>
        </IonModal>
        <div className="mt-7 px-5 lg:px-[10vw]  flex flex-col items-center">
          <PasswordField
            label="Contraseña actual"
            onIonChange={(e) => setValue('contrasena_anterior', e.detail.value ?? '')}
            className="w-full"
          />
          <p className="mb-5 self-start">Ingresa una nueva contraseña.</p>
          <PasswordField
            label="Nueva contraseña"
            onIonChange={(e) => setValue('contrasena', e.detail.value ?? '')}
            className="w-full"
          />
          <PasswordField
            label="Confirmar contraseña"
            onIonChange={(e) => setValue('repetir_contrasena', e.detail.value ?? '')}
            className="w-full"
          />
          <Button className="w-full lg:w-2/3  " onClick={onSubmit}>
            Cambiar Contraseña
          </Button>
        </div>
      </Page>
    </>
  )
}
