import React from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const MenuListItemCard: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  return (
    <div className={'rounded-xl  p-5 bg-white drop-shadow-lg ' + className} {...rest}>
      {props.children}
    </div>
  )
}
