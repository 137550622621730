import React, { useEffect, useState } from 'react'
import { IonCheckbox, IonContent, IonModal } from '@ionic/react'
import { useClase, useSetRespuesta } from '../../models/query-hooks'
import { Spinner } from '../Spinner'
import { OpcionType } from '../../models/pregunta-store/pregunta'
import { Button } from '../Button/Button'
import { ResultadoClaseRespuesta } from '../../services/api'
import { BsChevronLeft } from 'react-icons/bs'
import { useHistory } from 'react-router'
import { RUTAS } from '../../constant'

interface Props extends React.ComponentProps<typeof IonModal> {
  claseId: number
  onClose: () => void
}

export const Cuestionario: React.FC<Props> = (props) => {
  const { claseId, ...rest } = props
  const { isLoading, data: clase } = useClase(claseId)
  const [OpcionesElegidas, setOpcionesElegidas] = useState<OpcionType[]>()
  const [Opciones, setOpciones] = useState<OpcionType[]>()
  const [CurrentPregunta, setCurrentPregunta] = useState<number>()
  const [UltimaPregunta, setUltimaPregunta] = useState(false)
  const [ResultadoRespuesta, setResultadoRespuesta] = useState<ResultadoClaseRespuesta>()
  const crearRespuesta = useSetRespuesta(claseId)
  const history = useHistory()

  useEffect(() => {
    if (!!clase && !!clase.preguntas) {
      const opciones = clase.preguntas.flatMap((pregunta) => pregunta.opciones) as OpcionType[]
      if (!!opciones && opciones.length > 0) {
        setCurrentPregunta(clase.preguntas[0].id)
        setOpciones(opciones)
      }
    }
  }, [clase])

  const setRespuestaItem = (opcionSeleccionada: OpcionType) => {
    let opcionesElegidas = OpcionesElegidas ? [...OpcionesElegidas] : []
    const opcionesFiltered = opcionesElegidas.filter((opcion) => opcionSeleccionada.pregunta_id !== opcion.pregunta_id)
    opcionesFiltered.push(opcionSeleccionada)
    setOpcionesElegidas([...opcionesFiltered])

    const opciones = Opciones ? [...Opciones] : []

    opciones.forEach((opt) => {
      if (opt.pregunta_id === opcionSeleccionada.pregunta_id) {
        opt.correcto = false
      }
    })

    const index = opciones.findIndex((opt) => opt.id === opcionSeleccionada.id)
    opciones[index].correcto = true
    setOpciones([...opciones])
  }

  const siguientePregunta = () => {
    if (clase?.preguntas) {
      const preguntas = [...clase?.preguntas]
      const cantidadPreguntas = preguntas.length
      const currentIndex = preguntas.findIndex((current) => current.id === CurrentPregunta)
      if (currentIndex < cantidadPreguntas - 1) {
        setCurrentPregunta(preguntas[currentIndex + 1].id)
        return
      }
      crearRespuesta.mutate(
        {
          opciones_ids:
            OpcionesElegidas?.map((opcion) => {
              return { id: opcion.id }
            }) ?? [],
        },
        {
          onSuccess: async (data) => {
            console.log('success', data)
            setResultadoRespuesta(data)
          },
        },
      )
    }
  }

  const goBack = () => {
    if (clase?.preguntas) {
      const preguntas = [...clase?.preguntas]
      const cantidadPreguntas = preguntas.length
      const currentIndex = preguntas.findIndex((current) => current.id === CurrentPregunta)
      console.log(currentIndex)
      if (currentIndex === 0) {
        props.onClose()
        return
      }
      setCurrentPregunta(preguntas[currentIndex - 1].id)
    }
  }

  return (
    <IonModal {...rest}>
      <Spinner show={isLoading || crearRespuesta.isLoading} />

      <div className="w-full h-full relative ">
        <div className="absolute bottom-0 w-full h-1/4 bg-gradient-to-b from-white to-yellow z-9" />
        {!!clase && !isLoading && !crearRespuesta.isLoading && (
          <>
            <div className=" h-full w-full flex flex-col  z-10">
              <div className="p-4 pt-8 pl-8">
                <a onClick={goBack}>
                  <BsChevronLeft size={20} />
                </a>
              </div>
              {!ResultadoRespuesta &&
                clase.preguntas?.map((pregunta) => (
                  <React.Fragment key={pregunta.id}>
                    {CurrentPregunta === pregunta.id && (
                      <div className="flex-1">
                        <div className="h-full flex flex-col">
                          <h1 className="text-center text-2xl mb-12 mt-6 "> {pregunta.pregunta} </h1>
                          <div className="flex-1">
                            <IonContent className="">
                              <div className="px-4">
                                {Opciones?.filter((opt) => opt.pregunta_id === pregunta.id).map((opcion) => (
                                  <div key={opcion.id} className=" flex justify-between items-center mb-1">
                                    <div>
                                      <span>{opcion.respuesta}</span>
                                    </div>
                                    <div>
                                      <IonCheckbox
                                        checked={opcion.correcto}
                                        onClick={(e) => {
                                          setRespuestaItem(opcion)
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </IonContent>
                          </div>
                          <div className=" flex-1 flex justify-center pb-4">
                            <Button
                              onClick={() => {
                                siguientePregunta()
                              }}
                              className="lg:w-2/3 self-center"
                            >
                              Siguiente
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              {!!ResultadoRespuesta && (
                <div className="flex-1 relative animate-appear">
                  <div className=" h-full w-full flex flex-col ">
                    <div className="py-10">
                      <h1 className="text-center text-2xl">
                        Respondiste {ResultadoRespuesta.opciones_correctas}{' '}
                        {ResultadoRespuesta.opciones_correctas === 1 ? 'pregunta' : 'preguntas'} correctamente!
                      </h1>
                    </div>
                    {ResultadoRespuesta.puntos_asignados > 0 && (
                      <>
                        <p className="my-4 text-center">
                          ¡Felicidades! Completaste exitosamente el vídeo {clase?.nombre} y acumulaste{' '}
                          {ResultadoRespuesta.puntos_asignados}{' '}
                          {ResultadoRespuesta.puntos_asignados === 1 ? 'punto' : 'puntos'} para canjear!
                        </p>
                        <p className="text-center">
                          Podes continuar continuar acumulando puntos, o podes canjear por los obsequios disponibles.
                        </p>
                        <div className="flex-1 flex items-end justify-center">
                          <Button
                            onClick={() => {
                              history.push(RUTAS.BENEFICIOS)
                              props.onClose()
                            }}
                            className="lg:w-2/3 self-center"
                          >
                            Canjear
                          </Button>
                        </div>
                      </>
                    )}
                    {ResultadoRespuesta.puntos_asignados === 0 && (
                      <p className="text-center">
                        Faltaron {ResultadoRespuesta.cantidad_preguntas - ResultadoRespuesta.opciones_correctas}{' '}
                        preguntas aprobar la clase, puedes repasar el video e intentar de nuevo.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </IonModal>
  )
}
