import { IonContent, IonModal } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

import { Button, CategoriaItemClases, Cuestionario, NavHeader, Page } from '../components'
import { RUTAS } from '../constant'
import { useIsMovil } from '../helpers'
import { Categoria } from '../models/categoria/categoria'
import { Clase } from '../models/clase/clase'
import { useCategoria, useClase, useSetIngreso, useSetPorcentaje } from '../models/query-hooks'

export const ClaseDetail: React.FC = observer(() => {
  const match = useRouteMatch()
  // @ts-ignore
  const { isLoading, data: clase } = useClase(match.params.id)
  const { data: categoria } = useCategoria(clase?.categoria_padre)
  const [CurrentTime, setCurrentTime] = useState(0)
  const [Seeking, setSeeking] = useState(false)
  const { mutate: setIngreso } = useSetIngreso()
  const { mutate: updatePorcentaje } = useSetPorcentaje()
  const history = useHistory()
  const location = useLocation()
  const [ModalCuestionario, setModalCuestionario] = useState(false)
  const [Render, setRender] = useState(false)
  const isMovil = useIsMovil()

  const updateCompletedTime = (evt: any) => {
    const videoPlayer: any = document.getElementById('clase-video')
    const { currentTime, duration } = evt.target as any
    if (!Seeking) {
      const porcentaje = Math.floor((currentTime / duration) * 100)
      const porcentajeCompletado = Math.floor((CurrentTime / duration) * 100)

      if (porcentaje > 99 && !clase?.aprobada) {
        setModalCuestionario(true)
      }
      if (porcentaje > porcentajeCompletado && !!clase) {
        setCurrentTime(currentTime)
        updatePorcentaje({ clase: clase.id, porcentaje: porcentaje })
      }
    } else {
      if (CurrentTime < currentTime) {
        videoPlayer.currentTime = CurrentTime
      }
      setSeeking(false)
    }
  }

  const onLoadedMetadata = (clase: Clase) => {
    return () => {
      const video: any = document.getElementById('clase-video')
      const currentTime = Math.floor((clase.porcentaje * video.duration) / 100)
      video.currentTime = currentTime
      setCurrentTime(currentTime)
    }
  }

  React.useEffect(() => {
    if (location.pathname.includes(`/clase/`)) {
      setRender(true)
      setIngreso()
      return
    }
    setRender(false)
  }, [location])

  React.useEffect(() => {
    return () => {
      setModalCuestionario(false)
    }
  }, [])

  return Render ? (
    <Page header={<NavHeader className={isMovil ? 'bg-black text-white' : ''} />} full>
      {!!clase && (
        <Cuestionario isOpen={ModalCuestionario} claseId={clase.id} onClose={() => setModalCuestionario(false)} />
      )}

      <div className="h-full flex flex-col">
        {!isLoading && !!clase && (
          <div className="w-full">
            <div className="relative">
              <video
                onTimeUpdate={updateCompletedTime}
                onLoadedMetadata={onLoadedMetadata(clase)}
                onSeeking={(evt: any) => {
                  setSeeking(true)
                }}
                controls
                className="h-auto lg:h-[70vh] w-auto mx-auto "
                id={'clase-video'}
                src={clase.video}
                preload="auto"
              />
            </div>
          </div>
        )}
        {/* <IonContent class="flex-1"> */}
        <div className="p-5 lg:px-44">
          <div className="px-5 mb-5">
            <h3>{clase?.nombre}</h3>
            <p>{clase?.descripcion}</p>
          </div>
          {categoria?.sub_categorias?.map((categoria: Categoria) => {
            return (
              <React.Fragment key={categoria.id}>
                {categoria.id === clase?.categoria && (
                  <div className="mb-5">
                    <CategoriaItemClases categoria={categoria} key={categoria.id} />
                  </div>
                )}
                {categoria.id !== clase?.categoria && (
                  <div
                    key={categoria.id}
                    className="rounded-xl flex gap-2 items-center mb-5 p-3 bg-white drop-shadow-lg cursor-pointer"
                    onClick={() => history.push(RUTAS.CATEGORIA(categoria.id))}
                  >
                    <CircularProgressbar
                      value={categoria.porcentaje_completado}
                      text={`${categoria.porcentaje_completado}%`}
                      className="h-10 w-10 mr-5"
                      styles={buildStyles({ textColor: 'rgb(135, 116, 254)', pathColor: 'rgb(135, 116, 254)' })}
                    />
                    <div className="flex grow ">
                      <h5>{categoria.nombre}</h5>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </div>
        {/* </IonContent> */}
      </div>
    </Page>
  ) : (
    <></>
  )
})
