import { IonContent } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { FiX } from 'react-icons/fi'
import Modal from 'react-modal'
import { useNotificaciones, useSetVistaNotificacion } from '../../models/query-hooks'
import { Notificacion } from '../../services/api'
import './NotificationTray.css'

Modal.setAppElement('#root')

interface Props extends React.ComponentProps<typeof Modal> {}
export const NotificationTray: React.FC<Props> = observer((props) => {
  const { overlayClassName, ...rest } = props
  const { data: notificaciones } = useNotificaciones()
  const [NotificacionesItems, setNotificaciones] = useState<Notificacion[]>()
  const eliminarNotificacion = useSetVistaNotificacion()

  React.useEffect(() => {
    setNotificaciones(notificaciones)
  }, [notificaciones])

  return (
    <>
      <Modal
        {...rest}
        overlayClassName="notification-tray-overlay"
        className="h-[calc(100vh-4rem)] w-[100vw] lg:h-[32rem] lg:w-64 absolute top-16 lg:right-5  bg-white p-4 rounded-t-lg  lg:rounded-lg shadow-[35px_35px_60px_15px_rgba(0,0,0,0.3)] outline-none"
      >
        <h3 className="text-center">Notificaciones</h3>
        <IonContent className="lg:h-[28rem]">
          <div>
            {NotificacionesItems?.map((notificacion) => {
              if (notificacion.visto) return null
              return (
                <div key={notificacion.id} className="flex justify-between py-4 px-2">
                  <div>
                    <h6> {notificacion.titulo}</h6>
                    <p> {notificacion.mensaje}</p>
                  </div>
                  <div
                    onClick={() => {
                      let notificaciones = [...NotificacionesItems]
                      notificaciones = notificaciones.map((item) => {
                        if (item.id === notificacion.id) {
                          item.visto = true
                        }
                        return item
                      })

                      setNotificaciones([...notificaciones])
                      eliminarNotificacion.mutate({ id: notificacion.id })
                    }}
                  >
                    <FiX size={20} />
                  </div>
                </div>
              )
            })}
          </div>
        </IonContent>
      </Modal>
    </>
  )
})
