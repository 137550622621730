import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 *  clase model.
 */
export const InsigniaModel = types.model("Insignia").props({
  id: types.number,
  nombre: types.string,
  icono: types.string,
})

type InsigniaType = Instance<typeof InsigniaModel>
export interface Insignia extends InsigniaType {}
type InsigniaSnapshotType = SnapshotOut<typeof InsigniaModel>
export interface InsigniaSnapshot extends InsigniaSnapshotType {}
export const createInsigniaDefaultModel = () => types.optional(InsigniaModel, {} as any)

