import React from 'react'
import { useHistory } from 'react-router'
import { RUTAS } from '../../constant'
import { useIsMovil } from '../../helpers'
import { Clase } from '../../models/clase/clase'

interface ClaseProps {
  clase: Clase
}

export const ClaseListContainer: React.FC<ClaseProps> = (props) => {
  const { clase } = props
  const history = useHistory()
  const isMovil = useIsMovil()

  return (
    <div key={clase.id} className=" border-b-[1px] border-b-secondary/25 mt-2 pb-2 ">
      <div className="flex  cursor-pointer px-5" onClick={() => history.push(RUTAS.CLASE(clase.id))}>
        <div
          className="rounded-lg  w-[5.625rem] h-[5.625rem] flex-shrink-0"
          style={
            clase.thumbnail
              ? { backgroundImage: `url('${clase.thumbnail}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }
              : {}
          }
        ></div>
        <div className="flex flex-col justify-between  py-2 pl-2 grow">
          <div>
            <h6>{clase.nombre}</h6>
          </div>
          {!isMovil && <p className="w-1/2 line-clamp-2 text-ellipsis">{clase.descripcion}</p>}
          <div className="flex justify-end">
            <div>
              <span className="text-sm">+Puntos</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
