import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router'
import { NavHeader, Page } from '../components'
import { RUTAS } from '../constant'
import { usePublicaciones } from '../models/query-hooks'

export const Feed: React.FC = observer(() => {
  const [NextPageNull, setNextPageNull] = useState(false)
  const { data: posts, isLoading, fetchNextPage } = usePublicaciones()
  const history = useHistory()

  useEffect(() => {
    console.log(posts)
    if (posts?.pages && !posts?.pages[posts.pages.length - 1].nextCursor) {
      setNextPageNull(true)
    }
  }, [posts])

  return (
    <Page header={<NavHeader title="Muro" />}>
      {!!posts?.pages && (
        <div className="lg:w-full mx-auto w-full  ">
          <IonInfiniteScroll
            position="bottom"
            onIonInfinite={() => {
              if (posts?.pages[posts.pages.length - 1].nextCursor) {
                fetchNextPage()
                return
              }
            }}
          >
            <div className="w-full grid grid-cols-2 lg:grid-cols-4 p-5 gap-3 ">
              {posts?.pages.map((posts, index) => (
                <React.Fragment key={index}>
                  {posts.publicaciones.map((post) => (
                    <React.Fragment key={post.id}>
                      <div>
                        <div
                          className={`rounded-xl aspect-square p-2 bg-red  cursor-pointer`}
                          onClick={() => history.push(RUTAS.PUBLICACION(post.id))}
                          style={
                            !!post.imagenes && post.imagenes?.length > 0 && post.imagenes[0].imagen
                              ? {
                                  backgroundImage: `url('${post.imagenes[0].imagen}')`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                }
                              : {}
                          }
                        ></div>
                        <p className="text-s text-center line-clamp-2  font-bold  mt-1 text-ellipsis leading-normal">
                          {post.titulo}
                        </p>
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </div>
            {!NextPageNull && <IonInfiniteScrollContent loadingSpinner={'crescent'}></IonInfiniteScrollContent>}
          </IonInfiniteScroll>
        </div>
      )}
    </Page>
  )
})
