import { Capacitor } from '@capacitor/core'
import OneSignal from 'onesignal-cordova-plugin'
import { useEffect, useState } from 'react'
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import { useDebounce, useToaster } from '../../helpers'
import { Consulta, NuevaContrasena } from '../../services/api'
import { useStores } from '../root-store/root-store-context'

export const useRequestToaster = (action: 'mutate' | 'query') => {
  const addToast = useToaster()
  return (type: 'success' | 'error' = 'error', msg?: string, result: any = null) => {
    if (type === 'error') {
      if (msg) {
        addToast(msg, 'error')
        return
      }

      if (result.data) {
        const error: any = result.data[Object.keys(result.data)[0]][0]
        addToast(error, 'error')
        return
      }

      addToast(
        action === 'mutate'
          ? 'No se pudo guardar la actualización, intente más tarde.'
          : 'No se pudieron obtener datos, intente más tarde.',
        'error',
      )
    }

    if (type === 'success') {
      addToast(!!msg ? msg : 'Los datos fueron obtenidos correctamente.', 'success')
      return
    }
  }
}

export const useClase = (claseId?: number) => {
  const rootStore = useStores()
  const { loginStore } = rootStore
  const { usuario } = loginStore

  const { api } = rootStore.environment
  const addToast = useRequestToaster('query')

  const result = async () => {
    const result = await api.getClase(claseId)
    if (result.kind !== 'ok') {
      addToast('error')
      throw new Error(result.kind)
    }

    return result.clase
  }
  return useQuery(['clase', claseId], result, { enabled: !!claseId && !!usuario?.token })
}

export const useClases = (categoriaId?: number | null) => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore

  const addToast = useRequestToaster('query')

  const getData = async ({ pageParam = 1 }) => {
    const result = await api.getClases(pageParam, categoriaId)
    if (result.kind !== 'ok') {
      addToast('error')
      throw new Error(result.kind)
    }
    return result
  }
  return useInfiniteQuery(['clases', categoriaId], getData, {
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
    enabled: !!usuario?.token,
  })
}

export const useBuscarClases = (search?: string | null) => {
  const rootStore = useStores()
  const { loginStore } = rootStore
  const { usuario } = loginStore
  const { api } = rootStore.environment
  const addToast = useRequestToaster('query')

  const searchDebouce = useDebounce(search)

  const getData = async ({ pageParam = 1 }) => {
    if (searchDebouce) {
      const result = await api.getClasesBuscador(searchDebouce)
      if (result.kind !== 'ok') {
        addToast('error')
        throw new Error(result.kind)
      }
      return result.clases
    }
  }
  return useQuery(['clases-busqueda', searchDebouce], getData, {
    enabled: !!searchDebouce && !!usuario?.token,
  })
}

export const useClasesRelacionadas = (categoriaId?: number) => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore

  const addToast = useRequestToaster('query')

  const result = async () => {
    if (categoriaId) {
      const result = await api.getClaseRelacionadas(categoriaId)
      if (result.kind !== 'ok') {
        addToast('error')
        throw new Error(result.kind)
      }

      return result.clases
    }
  }
  return useQuery(['clases-relacionadas'], result, { enabled: !!categoriaId && !!usuario?.token })
}

export const useClasesDestacadas = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore

  const addToast = useRequestToaster('query')
  const result = async () => {
    const result = await api.getClasesDestacadas()
    if (result.kind !== 'ok') {
      addToast('error')
      throw new Error(result.kind)
    }

    return result.clases
  }
  return useQuery(['clases-relacionadas'], result, { enabled: !!usuario?.token })
}

export const useClasesMasVistas = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore

  const addToast = useRequestToaster('query')
  const result = async () => {
    const result = await api.getClasesMasVistas()
    if (result.kind !== 'ok') {
      addToast('error')
      throw new Error(result.kind)
    }

    return result.clases
  }
  return useQuery(['clases-mas-vistas'], result, { enabled: !!usuario?.token })
}

export const useClasesEmpezadas = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore

  const addToast = useRequestToaster('query')

  const result = async () => {
    const result = await api.getClasesEmpezadas()
    if (result.kind !== 'ok') {
      addToast('error')
      throw new Error(result.kind)
    }

    return result.clases
  }
  return useQuery(['clases-empezadas'], result, { enabled: !!usuario?.token })
}

export const useCategorias = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore

  const addToast = useRequestToaster('query')

  const result = async () => {
    const result = await api.getCategorias()

    if (result.kind !== 'ok') {
      addToast('error')
      throw new Error(result.kind)
    }
    return result.categorias
  }
  return useQuery('categorias', result, { enabled: !!usuario?.token })
}

export const useCategoria = (id?: number | null, onSuccess = ()=>{}) => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore

  const addToast = useRequestToaster('query')

  const result = async () => {
    if (id) {
      const result = await api.getCategoria(id)

      if (result.kind !== 'ok') {
        addToast('error')
        throw new Error(result.kind)
      }
      return result.categoria
    }
    return null
  }
  return useQuery(['categoria', id], result, { enabled: !!usuario?.token , onSuccess })
}

export const useInsignias = () => {
  const rootStore = useStores()
  const { loginStore } = rootStore
  const { usuario } = loginStore
  const { api } = rootStore.environment

  const addToast = useRequestToaster('query')

  const result = async () => {
    const result = await api.getInsignias()

    if (result.kind !== 'ok') {
      addToast('error')
      throw new Error(result.kind)
    }
    return result.insignia
  }
  return useQuery('insignias', result, { enabled: !!usuario?.token })
}

export const useUsuario = (id?: number) => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore
  const addToast = useRequestToaster('query')

  const getUser = async () => {
    if (!!id) {
      const result = await api.getUsuario(id)
      if (result.kind !== 'ok') {
        addToast('error')
        throw new Error(result.kind)
      }
      return result.usuario
    }
    return
  }
  return useQuery(['usuario', id], getUser, { enabled: !!usuario?.token })
}

export const useCurrentUsuario = () => {
  const rootStore = useStores()
  const id = rootStore.loginStore.usuario?.id
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore
  const addToast = useRequestToaster('query')

  const getUser = async () => {
    if (!!id) {
      const result = await api.getUsuario(id)
      if (result.kind !== 'ok') {
        addToast('error')
        throw new Error(result.kind)
      }
      return result.usuario
    }
    return
  }
  return useQuery(['usuario-current', id], getUser, { enabled: !!usuario?.token, cacheTime: 0 })
}

export const useIngresos = (fecha_gt?: string, fecha_lt?: string) => {
  const rootStore = useStores()
  const { api } = rootStore.environment

  const { loginStore } = rootStore
  const { usuario } = loginStore
  const setIngreso = async () => {
    if (!!fecha_gt && !!fecha_lt) {
      const result = await api.getIngresos(fecha_gt, fecha_lt)
      if (result.kind !== 'ok') throw new Error(result.kind)
      return [...result.ingresos]
    }
    return
  }
  return useQuery(['ingresos', fecha_gt, fecha_lt], setIngreso, { enabled: !!usuario?.token })
}

export const useEstadisticasUsuario = () => {
  const rootStore = useStores()
  const { loginStore } = rootStore
  const { usuario } = loginStore
  const { api } = rootStore.environment
  const getEstadisticas = async () => {
    const result = await api.getEstadisticas()
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result.estadisticas
  }

  return useQuery('estadisticas', getEstadisticas, { enabled: !!usuario?.token })
}

export const usePublicaciones = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment

  const { loginStore } = rootStore
  const { usuario } = loginStore
  const getPosts = async ({ pageParam = 1 }) => {
    const result = await api.getPublicaciones(pageParam)
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result
  }

  return useInfiniteQuery(['posts'], getPosts, {
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
    enabled: !!usuario?.token,
  })
}

export const usePublicacion = (id?: number | null) => {
  const rootStore = useStores()
  const { api } = rootStore.environment

  const { loginStore } = rootStore
  const { usuario } = loginStore
  const getData = async () => {
    if (id) {
      const result = await api.getPublicacion(id)
      if (result.kind !== 'ok') throw new Error(result.kind)
      return result.publicacion
    }
    return null
  }

  return useQuery(['posts', id], getData, { enabled: !!id && !!usuario?.token })
}

export const usePublicacionesDestacadas = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment

  const { loginStore } = rootStore
  const { usuario } = loginStore
  const getData = async () => {
    const result = await api.getPublicacionesDestacadas()
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result.publicaciones
  }

  return useQuery(['posts-destacados'], getData, { enabled: !!usuario?.token })
}

export const useNotificaciones = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore
  const getData = async () => {
    const result = await api.getNotificaciones()
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result.notificaciones
  }

  return useQuery(['notificaciones'], getData, { refetchInterval: 10000, enabled: !!usuario?.token })
}

export const useBeneficios = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore
  const getData = async ({ pageParam = 1 }) => {
    const result = await api.getBeneficios(pageParam)
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result
  }

  return useInfiniteQuery(['beneficios'], getData, {
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
    enabled: !!usuario?.token,
    cacheTime: 0,
  })
}

export const useCanjes = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const { loginStore } = rootStore
  const { usuario } = loginStore
  const getData = async ({ pageParam = 1 }) => {
    const result = await api.getCanjesUsuario(pageParam)
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result
  }

  return useInfiniteQuery(['canjes'], getData, {
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
    enabled: !!usuario?.token,
  })
}

//mutations
export const useSetIngreso = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const setIngreso = async () => {
    const result = await api.setIngreso()
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result.ingreso
  }
  return useMutation(setIngreso)
}

export const useSetPorcentaje = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment

  type Params = Parameters<typeof api.updatePorcentajeCompletado>[0]

  const setPorcentaje = async (params: Params) => {
    const result = await api.updatePorcentajeCompletado(params)
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result.porcentaje
  }

  return useMutation(setPorcentaje)
}

export const useActualizarPerfil = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const addToast = useRequestToaster('mutate')
  type Params = Parameters<typeof api.updatePerfilUsuario>[0]

  const setPerfil = async (params: Params) => {
    const result = await api.updatePerfilUsuario(params)

    if (result.kind !== 'ok') {
      addToast('error')
      throw new Error(result.kind)
    }
    return result.usuario
  }

  return useMutation(setPerfil)
}

export const useActualizarContrasena = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const addToast = useRequestToaster('mutate')

  const mutate = async (params: NuevaContrasena) => {
    const result = await api.updateContrasena(params)

    if (result.kind !== 'ok') {
      console.log(result)
      addToast('error', '', result)
      throw new Error(result.kind)
    }
    return result.kind
  }

  return useMutation(mutate)
}

export const useSetLike = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  interface Params {
    publicacion_id: number
    like: boolean
  }
  const setLike = async (params: Params) => {
    const result = await api.updateLikePublicacion(params.publicacion_id, params.like)
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result.kind
  }
  return useMutation(setLike)
}

export const useCanjearBeneficio = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const toaster = useRequestToaster('mutate')
  interface Params {
    beneficio_id: number
  }
  const mutate = async (params: Params) => {
    const result = await api.canjearBeneficio(params.beneficio_id)
    if (result.kind !== 'ok') {
      // @ts-ignore
      toaster('error', result.data)
      throw new Error(result.kind)
    }
    return result.kind
  }
  return useMutation(mutate)
}

export const useCrearConsulta = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment

  const mutate = async (params: Consulta) => {
    const result = await api.agregarConsulta(params)
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result.kind
  }
  return useMutation(mutate)
}

export const useSetVistaNotificacion = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment

  const mutate = async (params: { id: number }) => {
    const result = await api.setNotificacionVista(params.id)
    if (result.kind !== 'ok') throw new Error(result.kind)
    return result.kind
  }
  return useMutation(mutate)
}

export const useSetRespuesta = (clase_id: number) => {
  const rootStore = useStores()
  const { api } = rootStore.environment

  const mutate = async (params: { opciones_ids: { id: number }[] }) => {
    const result = await api.setRespuestaClase(clase_id, params.opciones_ids)
    if (result.kind !== 'ok') throw new Error(result.kind)
    console.log('resultado', result.resultado)
    return result.resultado
  }
  return useMutation(mutate)
}

export const useSetDevice = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment

  const mutate = async (params: { userId: string; pushToken: string; activo: boolean }) => {
    const { userId, pushToken, activo } = params
    const result = await api.setDevice(userId, pushToken, activo)
    if (result.kind !== 'ok') throw new Error(result.kind)
    return
  }
  return useMutation(mutate)
}

export const useLogoutDevicePost = () => {
  const rootStore = useStores()
  const { api } = rootStore.environment
  const [DeviceID, setDeviceID] = useState<string>()

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      OneSignal.getDeviceState((status) => {
        setDeviceID(status.userId)
      })
    }
  }, [])

  const mutate = async () => {
    if (DeviceID) {
      const result = await api.postLogout(DeviceID)
      if (result.kind !== 'ok') throw new Error(result.kind)
    }
    return
  }
  return useMutation(mutate)
}
