import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import OneSignal from 'onesignal-cordova-plugin'

import { useEffect, useState } from 'react'

import { RootStore, RootStoreProvider, setupRootStore } from './models'
import { QueryClient, QueryClientProvider } from 'react-query'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

// progres circle
import 'react-circular-progressbar/dist/styles.css'

/* Theme variables */
import './theme/variables.css'
import './theme/custom.css'

import { RUTAS } from './constant'

import {
  ClaseDetail,
  Login,
  Home,
  Perfil,
  Feed,
  BeneficiosPage,
  CategoriasPage,
  Clases,
  PublicacionPage,
  CategoriaPage,
  CambioContrasenaPage,
} from './pages'
import { Capacitor } from '@capacitor/core'
const { ID_ONE_SIGNAL, GOOGLE_PROJECT_NUMBER, SAFARI_WEB_ID } = require('./config/env')

setupIonicReact()

const queryClient = new QueryClient({})

const App: React.FC = () => {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  // Kick off initial async loading actions
  useEffect(() => {
    ;(async () => {
      setupRootStore().then((rootStore) => {
        setRootStore(rootStore)
      })
      const numeral = require('numeral')
      try {
        numeral.register('locale', 'py_custom', {
          delimiters: {
            thousands: '.',
            decimal: ',',
          },
          abbreviations: {
            thousand: 'm',
            million: 'mm',
            billion: 'b',
            trillion: 't',
          },
          ordinal: function (number: number) {
            return number === 1 ? 'punto' : 'puntos'
          },
          currency: {
            symbol: 'Pts. ',
          },
        })
      } catch (e) {}
      numeral.locale('py_custom')
    })()

    setupOneSignal()

    // MOMENT
    const moment = require('moment-timezone')
    const esLocale = require('moment/locale/es')
    moment.updateLocale('es', esLocale)
    moment.tz.setDefault('America/Asuncion')
  }, [])

  const setupOneSignal = () => {
    if (!ID_ONE_SIGNAL) {
      if (Capacitor.getPlatform() === 'ios') {
        alert('ID_ONE_SIGNAL nulo, en iOS puede crear problemas')
      }
      return
    }

    if (Capacitor.getPlatform() === 'web') {
      const OneSignalWeb = (window as any).OneSignal || []
      OneSignalWeb.push(function () {
        OneSignalWeb.init({
          appId: ID_ONE_SIGNAL,
          allowLocalhostAsSecureOrigin: true,
          safari_web_id: SAFARI_WEB_ID,
          notifyButton: {
            enable: true,
          },
        })
      })
      OneSignalWeb.push(function () {
        OneSignalWeb.getSubscription(function (notOptedOut: any) {
          console.log('OneSignal notOptedOut', notOptedOut)
          OneSignalWeb.registerForPushNotifications()
        })
      })
      console.log(ID_ONE_SIGNAL)
    } else {
      OneSignal.setAppId(ID_ONE_SIGNAL)
      OneSignal.setNotificationOpenedHandler((data) => {
        //@ts-ignore
        const id: any = data.notification.additionalData.id
        if (!!id && rootStore?.environment.api) {
          rootStore?.environment.api.setNotificacionVista(id).then((result) => console.log(result))
        }
        console.log('OneSignalCapacitor.handleNotificationOpened', data, data.notification)
      })
    }
    // Registrar listeners de eventos
  }

  const ruta = (ruta: string, componente: React.ReactElement, exact = true) => (
    <Route exact={exact} path={ruta}>
      {componente}
    </Route>
  )

  if (!rootStore) return null
  return (
    <IonApp>
      <RootStoreProvider value={rootStore}>
        <QueryClientProvider client={queryClient}>
          <IonReactRouter>
            <IonRouterOutlet animated={false}>
              {ruta(RUTAS.LOGIN, <Login />)}
              {ruta(RUTAS.FEED, <Feed />)}
              {ruta(RUTAS.PUBLICACION(':id(\\d+)'), <PublicacionPage />)}
              {ruta(RUTAS.HOME, <Home />)}
              {ruta(RUTAS.CATEGORIAS, <CategoriasPage />)}
              {ruta(RUTAS.CLASES, <Clases />)}
              {ruta(RUTAS.CLASE(':id(\\d+)'), <ClaseDetail />)}
              {ruta(RUTAS.CATEGORIA(':id(\\d+)'), <CategoriaPage />)}
              {ruta(RUTAS.PERFIL, <Perfil />)}
              {ruta(RUTAS.CAMBIO_CONTRASENA, <CambioContrasenaPage />)}
              {ruta(RUTAS.BENEFICIOS, <BeneficiosPage />)}

              <Route exact path="/">
                <Redirect to={RUTAS.LOGIN} />
              </Route>
            </IonRouterOutlet>
          </IonReactRouter>
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        </QueryClientProvider>
      </RootStoreProvider>
    </IonApp>
  )
}
export default App
