import { IonImg } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { FiHeart } from 'react-icons/fi'
import { useRouteMatch } from 'react-router'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavHeader, Page } from '../../components'
import { useIsMovil } from '../../helpers'
import { usePublicacion, useSetLike } from '../../models/query-hooks'

export const PublicacionPage: React.FC = observer(() => {
  const match = useRouteMatch()
  // @ts-ignore
  const { data: publicacion } = usePublicacion(match.params.id)
  const [Like, setLike] = useState<boolean>(false)
  const [ExtraLike, setExtraLike] = useState<number>(0)
  const { mutate: setLikeOnline } = useSetLike()
  const isMovil = useIsMovil()

  return (
    <Page
      header={
        <NavHeader
          className={isMovil ? 'bg-black text-white' : ''}
          rightButton={
            <div
              className="cursor-pointer hover:text-red"
              style={Like ? { color: ' rgb(221 29 33)' } : {}}
              onClick={() => {
                const like = !Like
                setLike(like)
                if (publicacion) {
                  if (like) {
                    setExtraLike(ExtraLike + 1)
                  } else {
                    setExtraLike(ExtraLike - 1)
                  }
                  setLikeOnline({ like: Like, publicacion_id: publicacion.id })
                }
              }}
            >
              <FiHeart size={20} />
            </div>
          }
        />
      }
    >
      <Swiper className="h-[13rem] lg:h-[70vh] " pagination={true} modules={[Pagination]}>
        {!publicacion?.video &&
          publicacion?.imagenes?.map((imagen) => (
            <SwiperSlide key={imagen.id}>
              <IonImg className="h-[13rem]  lg:h-[68vh]  " src={imagen.imagen} />
            </SwiperSlide>
          ))}
        {!!publicacion?.video && (
          <SwiperSlide>
            <div className="h-[13rem] lg:h-[68vh] flex align-center justify-center ">
              <video controls src={publicacion.video} />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
      <div className="px-5 my-5">
        <h2 className="mb-6">{publicacion?.titulo}</h2>
        <p>{publicacion?.texto}</p>
      </div>
    </Page>
  )
})
