import { IonCol, IonRow, IonText } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useEstadisticasUsuario } from '../models/query-hooks'
import { Spinner } from './Spinner'

const EstadisticasUsuarioContainer: React.FC = () => {
  const { data: estadisticas, isLoading } = useEstadisticasUsuario()

  return (
    <>
      <Spinner show={isLoading} />
      {!!estadisticas && (
        <IonRow>
          <IonCol>
            <IonRow className="ion-justify-content-center">
              <IonText className="ion-text-center text-sm font-bold">Clases Totales</IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonText className="ion-text-center text-sm"> {estadisticas.clases_totales}</IonText>
            </IonRow>
          </IonCol>
          <IonCol>
            <IonRow className="ion-justify-content-center">
              <IonText className="ion-text-center text-sm font-bold">Clases Completadas</IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonText className="ion-text-center text-sm"> {estadisticas.clases_completadas}</IonText>
            </IonRow>
          </IonCol>
          <IonCol>
            <IonRow className="ion-justify-content-center">
              <IonText className="ion-text-center text-sm font-bold">Clases faltantes</IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonText className="ion-text-center text-sm"> {estadisticas.clases_faltantes}</IonText>
            </IonRow>
          </IonCol>
        </IonRow>
      )}
    </>
  )
}

export default observer(EstadisticasUsuarioContainer)
