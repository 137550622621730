import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { NavHeader, Page, SearchBar, Spinner } from '../../components'
import { CategoriaCardContainer } from '../../components/CategoriaCardContainer/CategoriaCardContainer'
import { RUTAS } from '../../constant'
import { useIsMovil } from '../../helpers'
import { useCategorias } from '../../models/query-hooks'

const movilHeight = (index: number) => ((index + 1) % 2 === 0 ? 'h-[16.25rem]' : 'h-40')

export const CategoriasPage: React.FC = (props) => {
  const { data: categorias, isLoading } = useCategorias()
  const history = useHistory()
  const isMovil = useIsMovil()
  const [Busqueda, setBusqueda] = useState<string>('')
  const CateoriasFiltadas = useMemo(
    () =>
      categorias?.filter((cateoria) =>
        categorias?.filter((categoria) => {
          if (!Busqueda) return true
          const titulo = categoria.nombre.toLowerCase()
          return titulo.includes(Busqueda.toLowerCase())
        }),
      ),
    [categorias, Busqueda],
  )

  return (
    <Page header={<NavHeader title="Categorias" />}>
      <div className="p-4 rounded-lg shadow-lg my-6">
        <SearchBar value={Busqueda} onIonChange={(e) => setBusqueda(e.detail.value ?? '')} />
      </div>
      <div className="columns-2 lg:columns-4 p-5 gap-3 ">
        <Spinner show={isLoading} />
        {CateoriasFiltadas?.map((categoria, index) => {
          return <CategoriaCardContainer categoria={categoria} key={categoria.id} />
        })}
      </div>
    </Page>
  )
}
