import { IAnyModelType, IComplexType, Instance, SnapshotOut, types } from 'mobx-state-tree'
import { ClaseModel } from '../clase/clase'

/**
 * Categoria model.
 */

export const CategoriaModel = types.model('Categoria').props({
  id: types.identifierNumber,
  nombre: types.string,
  descripcion: types.maybeNull(types.string),
  icono: types.maybeNull(types.string),
  insignia_icono: types.maybeNull(types.string),
  completada: types.boolean,
  cantidad_clases: types.number,
  cantidad_vistas: types.number,
  fecha_modificacion: types.string,
  fondo: types.maybeNull(types.string),
  clases: types.array(ClaseModel),
  porcentaje_completado: types.number,
  sub_categorias: types.array(types.late((): IAnyModelType => CategoriaModel)),
  fondo_titulo: types.maybeNull(types.string),
  fondo_titulo_color: types.maybeNull(types.string),
  titulo_color_texto: types.maybeNull(types.string),
})

type CateroriaType = Instance<typeof CategoriaModel>
export interface Categoria extends CateroriaType {}
type CategoriaSnapshotType = SnapshotOut<typeof CategoriaModel>
export interface CategoriaSnapshot extends CategoriaSnapshotType {}
export const createCategoriaDefaultModel = () => types.optional(CategoriaModel, {} as any)
