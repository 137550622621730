import { IonAvatar, IonImg } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { AiOutlineEdit } from 'react-icons/ai'
import { useStoreUser } from '../helpers'
import { UsuarioSnapshot } from '../models/user/user'
import { ImgAsset } from './ImgAsset/ImgAsset'

type AvatarProps = React.HTMLAttributes<HTMLDivElement>

interface Props extends AvatarProps {
  usuario?: UsuarioSnapshot
}
export const UserAvatar: React.FC<Props> = observer((props) => {
  const usuario = !!props.usuario ? props.usuario : useStoreUser()
  const { className, ...rest } = props

  if (!usuario) return null
  return (
    <div>
      <div className={'rounded-full overflow-hidden    ' + className} {...rest}>
        {!!usuario.imagen_perfil ? <IonImg src={usuario.imagen_perfil} /> : <ImgAsset selected="user" />}
      </div>
      {!!props.onClick && (
        <div className=" relative bottom-12 w-28 flex justify-end">
          <div
            onClick={() => {
              //@ts-ignore
              props.onClick()
            }}
            className="w-10 h-10 rounded-full bg-white flex justify-center items-center cursor-pointer shadow-sm"
          >
            <AiOutlineEdit />
          </div>
        </div>
      )}
    </div>
  )
})
