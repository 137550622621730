import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { BsPlayCircle } from 'react-icons/bs'
import { useHistory } from 'react-router'
import { RUTAS } from '../constant'
import { Categoria } from '../models/categoria/categoria'

interface Props {
  categoria: Categoria
}
export const CategoriaItemClases: React.FC<Props> = (props) => {
  const { categoria } = props
  const history = useHistory()
  return (
    <div className="py-2 px-3 ">
      <div className="flex items-center ">
        <CircularProgressbar
          value={categoria.porcentaje_completado}
          text={`${categoria.porcentaje_completado}%`}
          className="h-10 w-10  mr-3 text-purple"
          styles={buildStyles({textColor:"rgb(135, 116, 254)", pathColor: "rgb(135, 116, 254)"})}
        />
        <div className="flex items-center flex-1 justify-between">
          <h5> {categoria.nombre} </h5>
          <span className="font-normal text-sm text-secondary">{categoria.clases.length} videos</span>
        </div>
      </div>
      {categoria.clases.map((clase) => (
        <div
          key={clase.id}
          className="p-4 flex items-center  cursor-pointer  hover:text-purple hover:underline"
          onClick={() => history.push(RUTAS.CLASE(clase.id))}
        >
          <BsPlayCircle className="mr-3" /> <span>{clase.nombre}</span>
        </div>
      ))}
    </div>
  )
}
