import './Button.css'
import React from 'react'
import { IonButton } from '@ionic/react'

type ButtonProps = React.ComponentProps<typeof IonButton>

export const Button: React.FC<ButtonProps> = (props) => {
  const { className, ...ionProps } = props

  return <IonButton className={'custom-button  ' + className} {...ionProps} />
}
