import { IonContent } from '@ionic/react'
import { AiOutlineRight } from 'react-icons/ai'
import { BsPlayCircle } from 'react-icons/bs'
import { useHistory } from 'react-router'
import { RUTAS } from '../../constant'
import { Clase } from '../../models/clase/clase'
import { useClasesDestacadas } from '../../models/query-hooks'

const thumbnailStyle = (clase: Clase) =>
  clase.thumbnail
    ? {
        backgroundImage: `url('${clase.thumbnail}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    : {}

export const ClaseDestacadaContainer: React.FC = (props) => {
  const history = useHistory()
  const { data: destacadas } = useClasesDestacadas()
  return (
    <div>
      <div className=" flex justify-between px-5 pb-4">
        <div>
          <h3>Destacadas</h3>
        </div>
        <div className=" flex items-center gap-1 cursor-pointer" onClick={() => history.push(RUTAS.CLASES)}>
          <span> Ver todos </span> <AiOutlineRight size={15} />
        </div>
      </div>
      <div>
        <IonContent scrollX={true} scrollY={false} className={'h-80 w-full '}>
          <div className="flex  ">
            {destacadas?.map((clase) => (
              <div key={clase.id} className="ml-4 cursor-pointer" onClick={() => history.push(RUTAS.CLASE(clase.id))}>
                <div
                  className="shrink-0 justify-center  p-5 bg-red rounded-xl h-56 w-56 "
                  style={thumbnailStyle(clase)}
                ></div>
                <div className="py-3 flex">
                  <div className="mr-2">
                    <BsPlayCircle size={30} className="text-black" />
                  </div>
                  <div>
                    <h6>{clase.nombre}</h6>
                    <span>Shell Staff</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </IonContent>
      </div>
    </div>
  )
}
