import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { InsigniaModel, InsigniaSnapshot } from '../insignia/insignia'
import { withEnvironment } from '../extensions/with-environment'

/**
 * Insignia Store
 */
export const InsigniaStoreModel = types
  .model('InsigniaStore')
  .extend(withEnvironment)
  .props({
    insignias: types.optional(types.array(InsigniaModel), []),
  })
  .actions((self) => ({
    setInsignias: (insigniaSnapshots: InsigniaSnapshot[]) => {
      self.insignias.replace(insigniaSnapshots)
    },
  }))
  .actions((self) => ({
    //api actions
  }))

type InsigniaStoreType = Instance<typeof InsigniaStoreModel>
export interface InsigniaStore extends InsigniaStoreType {}
type InsigniaStoreSnapshotType = SnapshotOut<typeof InsigniaStoreModel>
export interface InsigniaStoreSnapshot extends InsigniaStoreSnapshotType {}
export const createInsigniaStoreDefaultModel = () => types.optional(InsigniaStoreModel, {})
