import React, { useState } from 'react'
import { AiFillGift } from 'react-icons/ai'
import { BsBook, BsChevronLeft, BsHouseDoor } from 'react-icons/bs'
import { HiOutlineUserCircle } from 'react-icons/hi'
import { MdDynamicFeed } from 'react-icons/md'
import { RiLogoutBoxLine } from 'react-icons/ri'
import { useHistory } from 'react-router'
import { RUTAS } from '../../constant'
import { useLogout } from '../../helpers'
import { useCurrentUsuario } from '../../models/query-hooks'

interface Props {
  header?: React.ReactNode
  onNavigateExtraAction?: any
}

export const SideMenu: React.FC<Props> = (props) => {
  const history = useHistory()
  const logout = useLogout()
  const {data:usuario} = useCurrentUsuario()
  const onNavigate = !!props.onNavigateExtraAction ? props.onNavigateExtraAction : () => {}
  const [styles, setStyles] = useState<React.CSSProperties>({})

  React.useEffect(()=>{
    const styles: React.CSSProperties = {}
    if(usuario?.tapiz_aside_menu){
      styles.backgroundImage = `url(${usuario.tapiz_aside_menu})`
      styles.backgroundSize = "cover"
      styles.backgroundPosition = "center"
      styles.backgroundRepeat = "no-repeat"
    }
    setStyles(styles)
  }, [usuario])

  return (
    <>
      {props.header}
      <div className=" h-full w-full bg-white/80  flex flex-col" style={styles}>
        <div className="p-5">
          <div
            className="flex items-center mb-2 cursor-pointer"
            onClick={() => {
              onNavigate()
              history.push(RUTAS.HOME)
            }}
          >
            <BsHouseDoor size={28} className="mr-2" />
            <h1>Inicio</h1>
          </div>
          <div
            className="flex items-center mb-2 cursor-pointer"
            onClick={() => {
              onNavigate()
              history.push(RUTAS.FEED)
            }}
          >
            <MdDynamicFeed size={28} className="mr-2" />
            <h1>Feed</h1>
          </div>
          <div
            className="flex items-center mb-2 cursor-pointer"
            onClick={() => {
              onNavigate()
              history.push(RUTAS.CLASES)
            }}
          >
            <BsBook size={28} className="mr-2" />
            <h1>Cursos</h1>
          </div>
        </div>
        <div className="  flex-1 relative ">
          <div className="absolute bottom-14 pl-5">
            <div
              className="flex items-center mb-2 cursor-pointer"
              onClick={() => {
                onNavigate()
                history.push(RUTAS.BENEFICIOS)
              }}
            >
              <AiFillGift size={28} className="mr-2" />
              <div className="" />
              <h1>Canjear Puntos</h1>
            </div>
            <div
              className="flex items-center mb-2 cursor-pointer"
              onClick={() => {
                onNavigate()
                history.push(RUTAS.PERFIL)
              }}
            >
              <HiOutlineUserCircle size={28} className="mr-2" />
              <h1>Perfil</h1>
            </div>
            <div className="flex items-center mb-2 cursor-pointer" onClick={logout}>
              <RiLogoutBoxLine size={28} className="mr-2" /> <h1>Cerrar Sesión</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
