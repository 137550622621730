import { IonInput, IonItem, IonLabel } from '@ionic/react'
import React, { useState } from 'react'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import './TextField.css'

type TextFieldProps = React.ComponentProps<typeof IonInput> & {
  error?: string
  label?: string
  rightIcon?: React.ReactNode
}

export const TextField: React.FC<TextFieldProps> = (props) => {
  const { error, rightIcon,  className, label, ...ionProps } = props
  return (
    <>
      <div className={"custom-text-field bg-none " +className}>
        <div className="my-2 bg-white/50 shadow-lg px-3 py-1 rounded-xl">
          <div className="flex items-center justify-between">
            <div className='flex-1'>
              <IonLabel position="stacked" className="text-secondary ">
                {label}
              </IonLabel>
              <IonInput {...ionProps} />
            </div>
            {rightIcon}
          </div>
        </div>
        {!!error && <span className="text-red text-sm "> {error}</span>}
      </div>
    </>
  )
}

export const PasswordField: React.FC<TextFieldProps> = (props) => {
  const { type, rightIcon, ...rest } = props
  const [Show, setShow] = useState(false)

  return (
    <TextField
      rightIcon={
        <div className="cursor-pointer" onClick={() => setShow(!Show)}>
          {Show ? <BsEye /> : <BsEyeSlash />}
        </div>
      }
      type={Show ? 'text' : 'password'}
      {...rest}
    />
  )
}
