import { IonInput } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'

interface Props extends React.ComponentProps<typeof IonInput> {
  onSubmitSearch?: () => void
}
export const SearchBar: React.FC<Props> = (props) => {
  const { className, placeholder, onSubmitSearch, ...otherProps } = props
  const [Focus, setFocus] = useState(false)

  return (
    <div className={' rounded-lg bg-gradient-to-r from-yellow w-full flex items-center px-5 '}>
      <div onClick={onSubmitSearch}>
        <AiOutlineSearch size={20} />
      </div>
      <IonInput
        {...otherProps}
        placeholder="Buscar"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onKeyDown={(event) => {
          if (Focus && event.key === 'Enter') {
            !!onSubmitSearch && onSubmitSearch()
          }
        }}
      />
    </div>
  )
}
