import { IonContent } from '@ionic/react'
import { useEffect, useState } from 'react'
import { AiOutlineRight } from 'react-icons/ai'
import { BsChevronRight, BsPlayCircle } from 'react-icons/bs'
import { useHistory } from 'react-router'
import { RUTAS } from '../../constant'
import { Clase } from '../../models/clase/clase'
import { Publicacion } from '../../models/publicacion/publicacion'

interface Props {
  publicacion: Publicacion
}

export const PublicacionDestacadaContainer: React.FC<Props> = (props) => {
  const history = useHistory()
  const { publicacion } = props
  const [gradient, setGradient] = useState('')

  useEffect(() => {
    if (!publicacion.color_fondo) {
      setGradient('bg-gradient-to-tr from-yellow to-white shadow-lg')
    } else {
      setGradient('')
    }
  }, [])
  return (
    <div
      className={'rounded-lg  p-5 mb-5 pt-16  h-60 flex flex-col justify-end ' + gradient}
      style={publicacion.color_fondo ? { backgroundColor: publicacion.color_fondo } : {}}
    >
      <h4
        className="text-red mb-5 text-2xl "
        style={publicacion.color_titulo ? { color: publicacion.color_titulo } : {}}
      >
        {publicacion.titulo}
      </h4>
      <p
        className="w-3/4 leading-[1.75rem] line-clamp-2 text-ellipsis font-bold"
        style={publicacion.color_titulo ? { color: publicacion.color_titulo } : {}}
      >
        {publicacion.texto}
      </p>
      <div className="flex justify-between items-end">
        <div></div>
        <div
          className="cursor-pointer rounded-full bg-white p-4"
          onClick={() => history.push(RUTAS.PUBLICACION(publicacion.id))}
        >
          <BsChevronRight size={10} />
        </div>
      </div>
    </div>
  )
}
