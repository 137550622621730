import { IonContent, IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useIsLogin, useIsMovil, useRedirectLogin, useStoreUser } from '../../helpers'
import { SideMenu } from '../SideMenu/SideMenu'
import { Spinner } from '../Spinner'
import './Page.css'
interface Props {
  noLoginRequired?: boolean
  noScroll?: boolean
  loading?: boolean
  header: React.ReactNode
  full?: boolean
}

export const Page: React.FC<Props> = observer((props) => {
  const redirectLogin = useRedirectLogin()
  const display = useIsLogin() || props.noLoginRequired
  const isMovil = useIsMovil()

  const footer = (
    <div className="w-full py-10">
      <p className="text-center text-secondary">Academia Shell © {new Date().getFullYear()} </p>
    </div>
  )

  useEffect(() => {
    if (!props.noLoginRequired) redirectLogin()
  }, [])

  if (!display) return null

  return (
    <IonPage id="main-content">
      {props.header}
      <div className="flex h-full">
        {!isMovil && (
          <div className="w-64 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
            <SideMenu />
          </div>
        )}
        {!props.noScroll && (
          <IonContent>
            {!props.loading && (
              <div className={`${props.full ? 'w-full' : 'lg:w-3/4'} mx-auto`}>
                {props.children} {!isMovil && footer}
              </div>
            )}
            {props.loading && (
              <div className="relative ">
                <div className=" absolute top-[45vh] w-full">
                  <Spinner show containerClass="" />
                </div>
              </div>
            )}
          </IonContent>
        )}
        {props.noScroll && (
          <div className="w-full h-full">
            <div className={`${props.full ? 'w-full' : 'lg:w-3/4'} h-full mx-auto`}>
              {props.children} {!isMovil && footer}
            </div>
          </div>
        )}
      </div>
    </IonPage>
  )
})
