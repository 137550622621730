import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { BsQuestionCircle, BsChevronRight } from 'react-icons/bs'
import { MdLogout } from 'react-icons/md'
import { FiCreditCard } from 'react-icons/fi'
import { useHistory } from 'react-router'
import { Button, MenuListItemCard, ModalAction, NavHeader, Page, UsuarioUpdate } from '../components'
import { UserAvatar } from '../components/UserAvatar'

import { useLogout } from '../helpers'
import { useStores } from '../models'
import {
  useCanjes,
  useClasesEmpezadas,
  useCrearConsulta,
  useCurrentUsuario,
  useEstadisticasUsuario,
} from '../models/query-hooks'

import { Consulta } from '../services/api'
import { RUTAS } from '../constant'

export const Perfil: React.FC = observer(() => {
  // const usuario = useStoreUser()
  const { data: usuario } = useCurrentUsuario()
  const [ModalConsulta, setModalConsulta] = useState(false)
  const [ModalCerrarSesion, setModalCerrarSesion] = useState(false)

  const [EditarPerfil, setEditarPerfil] = useState(false)
  const history = useHistory()

  const cerrarSesion = useLogout()

  return (
    <Page header={<NavHeader title="Mi perfil" />}>
      <ModalAction isOpen={ModalCerrarSesion} onRequestClose={() => setModalCerrarSesion(false)}>
        <div className="flex justify-center pb-5">
          <h5 className="w-1/2 font-semibold text-center">¿Desea cerrar session?</h5>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-1 lg:gap-5 ">
          <Button
            color="secondary"
            onClick={() => {
              setModalCerrarSesion(false)
              cerrarSesion()
            }}
          >
            Si
          </Button>
          <Button onClick={() => setModalCerrarSesion(false)}>Volver</Button>
        </div>
      </ModalAction>
      <ConsultaModal
        isOpen={ModalConsulta}
        onDidDismiss={() => setModalConsulta(false)}
        close={() => setModalConsulta(false)}
      />

      {!!usuario && (
        <>
          <div className="w-full bg-gray-100  p-5 flex flex-col items-center">
            <div className="flex flex-col items-center capitalize mb-5">
              <UserAvatar className="my-5 h-28 w-28" onClick={() => setEditarPerfil(!EditarPerfil)} />
              {!!usuario.last_name || !!usuario.first_name ? (
                <h2>
                  {usuario.first_name} {usuario.last_name}
                </h2>
              ) : (
                <h2>{usuario.username}</h2>
              )}
              {!!usuario.nro_documento && <span className="text-base"> CI: {usuario.nro_documento} </span>}
            </div>
            {!EditarPerfil && (
              <>
                <MenuListItemCard
                  onClick={() => history.push(RUTAS.BENEFICIOS)}
                  className="cursor-pointer  mb-28 flex items-center w-full lg:w-[30vw] "
                >
                  <FiCreditCard size={15} className="mr-3" /> <span> Canjear Productos</span>
                  <div className="flex items-center flex-1 justify-end">
                    <BsChevronRight size={15} />
                  </div>
                </MenuListItemCard>
                <MenuListItemCard
                  className="cursor-pointer mb-2  flex items-center  w-full lg:w-[30vw] "
                  onClick={() => history.push(RUTAS.CAMBIO_CONTRASENA)}
                >
                  <BsQuestionCircle size={15} className="mr-3" /> <span> Cambiar Contraseña</span>
                  <div className="flex items-center flex-1 justify-end">
                    <BsChevronRight size={15} />
                  </div>
                </MenuListItemCard>
                <MenuListItemCard
                  onClick={() => setModalCerrarSesion(true)}
                  className="cursor-pointer  mb-2 flex items-center  w-full lg:w-[30vw] "
                >
                  <MdLogout size={15} className="mr-3" /> <span> Cerrar Sesión</span>
                  <div className="flex items-center flex-1 justify-end">
                    <BsChevronRight size={15} />
                  </div>
                </MenuListItemCard>
              </>
            )}
          </div>
          {EditarPerfil && (
            <div className="mx-auto lg:w-1/2">
              <UsuarioUpdate onDone={() => setEditarPerfil(false)} />
            </div>
          )}
        </>
      )}
    </Page>
  )
})

type ModalProps = React.ComponentProps<typeof IonModal> & { close: any }

const ConsultaModal: React.FC<ModalProps> = (props) => {
  const { mutate: crearConsulta } = useCrearConsulta()
  const [ConsultaEdit, setConsulta] = useState<Consulta>({ asunto: '', texto: '' })

  const setField = (value: string, key: keyof Consulta) => {
    const consulta: any = { ...ConsultaEdit }
    consulta[key] = value

    setConsulta(consulta)
  }

  const onSubmit = () => crearConsulta(ConsultaEdit, { onSuccess: props.close })

  return (
    <IonModal {...props}>
      <IonToolbar>
        <IonTitle> Crear Consulta</IonTitle>
      </IonToolbar>
      <IonList>
        <IonItem>
          <IonLabel>Asunto</IonLabel>
          <IonInput
            onIonChange={(e) => setField(e.detail.value ? e.detail.value : '', 'asunto')}
            value={ConsultaEdit?.asunto}
            placeholder={'Asunto'}
          />
        </IonItem>
        <IonItem>
          <IonLabel>Mensaje</IonLabel>
          <IonTextarea
            onIonChange={(e) => setField(e.detail.value ? e.detail.value : '', 'texto')}
            value={ConsultaEdit?.texto}
            placeholder={'Mensaje'}
          />
        </IonItem>
      </IonList>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton onClick={props.close}>Cancelar</IonButton>
          </IonCol>
          <IonCol>
            <IonButton onClick={onSubmit}>Enviar Consulta</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  )
}
