import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle, IonGrid, IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToolbar
} from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStoreUser } from '../helpers'
import { useStores } from '../models'
import { useActualizarPerfil } from '../models/query-hooks'
import { Usuario, UsuarioSnapshot } from '../models/user/user'
import { UserAvatar } from './UserAvatar'

interface Props {
  onDone: any
}
export const UsuarioUpdate: React.FC<Props> = observer((props) => {
  const usuario = useStoreUser()
  const { loginStore } = useStores()
  const [Usuario, setUsuario] = useState<UsuarioSnapshot>()
  const { mutate: actualizarPerfil } = useActualizarPerfil()
  useEffect(() => {
    if (!!usuario) {
      setUsuario({ ...usuario })
    }
  }, [usuario])

  const setField = (data: any, campo: keyof UsuarioSnapshot) => {
    if (!!Usuario && !!data) {
      const state = { ...Usuario }
      // @ts-ignore
      state[campo] = data
      setUsuario(state)
    }
  }

  const selectPicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos,
      })
      setField(image.dataUrl, 'imagen_perfil')
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmit = () => {
    actualizarPerfil(Usuario as Usuario, {
      onSuccess: (data, variables, context) => {
        loginStore.setUsuario(data)
        props.onDone()
      },
      onError: (error, variables, context) => {
        console.log(error)
      },
    })
  }
  if (!Usuario) return null
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Editar Perfil</IonCardTitle>
        <IonCardContent>
          <IonToolbar className="border-none">
            <UserAvatar className="cursor-pointer" onClick={selectPicture} usuario={Usuario} slot="start" />
          </IonToolbar>
          <IonList>
            {/* <IonItem>
              <IonLabel position="floating">Nombre de Usuario</IonLabel>
              <IonInput
                placeholder="Nombre de Usuario"
                value={Usuario.username}
                onIonChange={(e) => setField(e.detail.value, 'username')}
              />
            </IonItem> */}
            <IonItem>
              <IonLabel position="floating">Numero de teléfono</IonLabel>
              <IonInput
                placeholder="Numero de telefono"
                inputMode="tel"
                value={Usuario.nro_telefono}
                onIonChange={(e) => setField(e.detail.value, 'nro_telefono')}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Localidad</IonLabel>
              <IonInput
                placeholder="Localidad"
                value={Usuario.localidad}
                onIonChange={(e) => setField(e.detail.value, 'localidad')}
              />
            </IonItem>
          </IonList>
          <IonGrid>
            <IonRow className="ion-justify-content-end">
              <IonButton onClick={onSubmit}>Guardar</IonButton>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCardHeader>
    </IonCard>
  )
})
