export const RUTAS = {
  HOME: '/inicio',
  FEED: '/feed',
  PUBLICACION: (id) => `/publicacion/${id}`,
  CLASE: (id) => `/clase/${id}`,
  CATEGORIA: (id) => `/categoria/${id}`,
  LOGIN: '/login',
  PERFIL: '/perfil',
  CAMBIO_CONTRASENA: '/cambio-contrasena',
  CATEGORIAS: '/categorias',
  CLASES: '/clases',
  BENEFICIOS: '/beneficios',
}
