import React, { useState } from 'react'
import { AiOutlineDown, AiOutlineRight } from 'react-icons/ai'
import { useHistory } from 'react-router'
import { RUTAS } from '../../constant'
import { useIsMovil } from '../../helpers'
import { useClasesMasVistas } from '../../models/query-hooks'
import { ClaseListContainer } from '../ClaseListContainer/ClaseListContainer'
import { Spinner } from '../Spinner'

export const MasVistas: React.FC = () => {
  const { data: masVistas, isLoading } = useClasesMasVistas()
  const [MostrarTodos, setMostrarTodo] = useState(false)

  return (
    <div>
      <div className=" flex justify-between px-5">
        <div>
          <h3>Más Vistas</h3>
        </div>
        <div className=" flex items-center gap-1 cursor-pointer" onClick={() => setMostrarTodo(!MostrarTodos)}>
          <span> Ver todos </span> {MostrarTodos ? <AiOutlineDown size={15} /> : <AiOutlineRight size={15} />}
        </div>
      </div>
      <div>
        <Spinner show={isLoading} />
        {MostrarTodos
          ? masVistas?.map((clase) => <ClaseListContainer clase={clase} key={clase.id} />)
          : masVistas?.slice(0, 3).map((clase) => <ClaseListContainer clase={clase} key={clase.id} />)}
      </div>
    </div>
  )
}
