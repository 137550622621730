import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { PreguntaModel } from '../pregunta-store/pregunta'

/**
 *  clase model.
 */
export const ClaseModel = types.model('Clase').props({
  id: types.identifierNumber,
  categoria: types.number,
  categoria_padre: types.maybeNull(types.number),
  nombre: types.string,
  video: types.string,
  thumbnail: types.maybeNull(types.string),
  descripcion: types.maybe(types.string),
  fecha: types.string,
  porcentaje: types.number,
  completada: types.boolean,
  puntos: types.number,
  preguntas: types.maybeNull(types.array(PreguntaModel)),
  aprobada: types.maybeNull(types.boolean),
})

type ClaseType = Instance<typeof ClaseModel>
type ClaseSnapshotType = SnapshotIn<typeof ClaseModel>
export interface ClaseSnapshot extends ClaseSnapshotType {}
export interface Clase extends ClaseSnapshot {}
export const createClaseDefaultModel = () => types.optional(ClaseModel, {} as any)
