import { IonSpinner } from '@ionic/react'
import React from 'react'

interface Props {
  show: boolean
  containerClass?: string
}
export const Spinner: React.FC<Props> = (props) => {
  if (!props.show) return null

  return (
    <div className={'flex items-center justify-center ' + props.containerClass}>
      <IonSpinner color="primary" />
    </div>
  )
}
