import { Instance, SnapshotOut, types } from 'mobx-state-tree'

/**
 *  usuario model.
 */
export const UsuarioModel = types.model('Usuario').props({
  id: types.number,
  username: types.string,
  first_name: types.string,
  last_name: types.string,
  email: types.string,
  nro_documento: types.maybeNull(types.string),
  nro_telefono: types.maybeNull(types.string),
  direccion: types.maybeNull(types.string),
  localidad: types.maybeNull(types.string),
  ciudad: types.maybeNull(types.string),
  imagen_perfil: types.maybeNull(types.string),
  date_joined: types.string,
  token: types.maybeNull(types.string),
  puntos: types.number,
  tapiz_aside_menu: types.maybeNull(types.string)
})

type UsuarioType = Instance<typeof UsuarioModel>
export interface Usuario extends UsuarioType {}
type UsuarioSnapshotType = SnapshotOut<typeof UsuarioModel>
export interface UsuarioSnapshot extends UsuarioSnapshotType {}
export const createUsuarioDefaultModel = () => types.optional(UsuarioModel, {} as any)
