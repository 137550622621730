import { Capacitor } from '@capacitor/core'
import { IonContent } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import OneSignal from 'onesignal-cordova-plugin'
import React, { useEffect } from 'react'
import { AiOutlineRight } from 'react-icons/ai'
import { BsChevronRight } from 'react-icons/bs'
import { useHistory } from 'react-router'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Buscador, ClaseDestacadaContainer, ImgAsset, MasVistas, NavHeader, Page } from '../components'
import { PublicacionDestacadaContainer } from '../components/PublicacionDestacadaContainer/PublicacionDestacada'
import { RUTAS } from '../constant'
import { useCategorias, useClasesDestacadas, usePublicacionesDestacadas, useSetDevice } from '../models/query-hooks'
import './Home.css'

export const Home: React.FC = observer(() => {
  const { data: categorias } = useCategorias()
  const { data: publicacionesDestacadas } = usePublicacionesDestacadas()
  const setDevice = useSetDevice()
  const history = useHistory()

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      OneSignal.getDeviceState((status) =>
        setDevice.mutate({ userId: status.userId, pushToken: status.pushToken, activo: true }),
      )
    }
  }, [])

  return (
    <Page
      header={
        <div>
          <NavHeader leftButton={<div></div>} />
        </div>
      }
    >
      <div className="mx-5 my-5 bg-yellow rounded-lg">
        <ImgAsset selected="bienvenida" />
      </div>
      <Buscador />
      <div className="flex flex-col gap-10 mt-10  mb-5  ">
        <div className="px-5">
          {!!publicacionesDestacadas && publicacionesDestacadas?.length > 0 && (
            <Swiper pagination modules={[Pagination]}>
              {publicacionesDestacadas?.map((publicacion) => (
                <SwiperSlide key={publicacion.id} className="pb-5 px-1">
                  <PublicacionDestacadaContainer publicacion={publicacion} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        <div className="mb-5 px-5">
          <div className="flex justify-between">
            <div>
              <h3>Categorias</h3>
            </div>
            <div onClick={() => history.push(RUTAS.CATEGORIAS)} className=" flex items-center cursor-pointer">
              <span> Ver todos </span> <AiOutlineRight size={15} />
            </div>
          </div>

          <IonContent scrollX={true} scrollY={false} className=" h-[12rem] lg:h-60">
            <div className="flex h-full items-center">
              {categorias?.map((categoria) => (
                <div key={categoria.id}>
                  <div
                    key={categoria.id}
                    className="shrink-0 mr-3 flex justify-center items-end  p-5 bg-red rounded-xl h-[5.625rem] lg:h-40 w-24 lg:w-40  cursor-pointer"
                    onClick={() => history.push(RUTAS.CATEGORIA(categoria.id))}
                    style={
                      categoria.fondo
                        ? {
                            backgroundImage: `url('${categoria.fondo}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                          }
                        : {}
                    }
                  ></div>
                  <div className="w-24 lg:w-40  h-[3rem]">
                    <p className="text-s text-center line-clamp-2  font-bold  mt-1 text-ellipsis leading-normal">
                      {categoria.nombre}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </IonContent>
        </div>
        <MasVistas />
        <ClaseDestacadaContainer />
      </div>
    </Page>
  )
})
