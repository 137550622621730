import { ApiResponse } from 'apisauce'
import { number } from 'mobx-state-tree/dist/internal'
import { UsuarioSnapshot } from '../../models/user/user'
import { ApiBase } from './api-base'
import { getGeneralApiProblem } from './api-problem'
import * as Types from './api.types'

/**
 * Manages all requests to the API.
 */
export class Api extends ApiBase {
  async getLogin(username: string, password: string): Promise<Types.GetUsuarioResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.post('/login/', { username, password })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        // console.log('problem', problem)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', usuario: data }
    } catch (e) {
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async getUsuario(id: number): Promise<Types.GetUsuarioResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get('/usuario/' + id + '/')

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        // console.log('problem', problem)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', usuario: data }
    } catch (e) {
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async updatePerfilUsuario(params: UsuarioSnapshot): Promise<Types.GetUsuarioResult> {
    if (!!params) {
      try {
        // make the api call
        const fData = new FormData()
        for (const [key, value] of Object.entries(params)) {
          if (key === 'imagen_perfil' && (value.includes('blob') || value.includes('data'))) {
            const imagenFile = await fetch(value)
            const blob = await imagenFile.blob()
            fData.append(key, blob, 'usuario.png')
          } else if (key !== 'imagen_perfil') {
            fData.append(key, value || '')
          }
        }

        const headers = {
          'Content-Type': 'multipart/form-data',
          Authorization: this.apisauce.headers.Authorization,
        }

        const response: ApiResponse<any> = await this.apisauce.axiosInstance.patch(`/actualizar-perfil/`, fData, {
          headers,
        })

        // the typical ways to die when calling an api
        if (!response.ok) {
          const problem = getGeneralApiProblem(response)
          if (problem) return problem
        }

        const { data } = response

        return { kind: 'ok', usuario: data }
      } catch (e) {
        // __DEV__ && console.tron.log(e.message)
        //@ts-ignore
        return { kind: 'bad-data' }
      }
    }

    return { kind: 'bad-data' }
  }

  async updateContrasena(contrasena: Types.NuevaContrasena): Promise<Types.PostDefault> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.patch(`/actualizar-contrasena/`, {
        ...contrasena,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async updateLikePublicacion(publicacion_id: number, like: boolean): Promise<Types.PostDefault> {
    try {
      // make the api call
      const headers = { Authorization: this.apisauce.headers.Authorization }
      const response: ApiResponse<any> = await this.apisauce.axiosInstance.patch(
        `/like-publicacion/`,
        {
          publicacion_id,
          like,
        },
        { headers },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async getCategorias(): Promise<Types.GetCategoriasResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(
        '/categorias/',
        // { amount: API_PAGE_SIZE },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', categorias: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getPublicacionesDestacadas(): Promise<Types.GetPublicacionesPaginatedResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(
        '/publicaciones-destacadas/',
        // { amount: API_PAGE_SIZE },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', publicaciones: data, nextCursor: null }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      return { kind: 'bad-data' }
    }
  }
  async getCategoria(id: number): Promise<Types.GetCategoriaResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(
        '/categorias/' + id + '/',
        // { amount: API_PAGE_SIZE },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', categoria: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      return { kind: 'bad-data' }
    }
  }
  async getClases(page: number, categoriaId?: number | null): Promise<Types.GetClasesResultPaginated> {
    try {
      // make the api call
      const query = !!categoriaId ? `?categoria=${categoriaId}` : ''

      const response: ApiResponse<any> = await this.apisauce.get(`/clases/` + query + `?page=${page}`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      const nextPage = data.next ? 1 + page : null

      return { kind: 'ok', clases: data.results, nextCursor: nextPage }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getClasesBuscador(search: string): Promise<Types.GetClasesResult> {
    try {
      // make the api call

      const response: ApiResponse<any> = await this.apisauce.get(`/clase/buscar/?search=${search}`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      return { kind: 'ok', clases: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getClasesMasVistas(): Promise<Types.GetClasesResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(
        `/mas-vistas/`,
        // { amount: API_PAGE_SIZE },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      return { kind: 'ok', clases: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getClaseRelacionadas(categoriaId: number): Promise<Types.GetClasesResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(
        `/clase-relacionada/`,
        +`?categoria=${categoriaId}`,
        // { amount: API_PAGE_SIZE },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      return { kind: 'ok', clases: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getClasesDestacadas(): Promise<Types.GetClasesResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(
        `/clases-destacadas/`,
        // { amount: API_PAGE_SIZE },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      return { kind: 'ok', clases: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getClasesEmpezadas(): Promise<Types.GetClasesResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/clase-empezada/`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      return { kind: 'ok', clases: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getClase(claseId?: number): Promise<Types.GetClaseResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/clases/${claseId}/`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', clase: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async updatePorcentajeCompletado(params: { clase: number; porcentaje: number }): Promise<Types.PostPorcentajeResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.post(`/actualizar-porcentaje/`, params)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', porcentaje: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async getInsignias(): Promise<Types.GetInsigniasResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get('/insignias/')

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      return { kind: 'ok', insignia: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async setIngreso(): Promise<Types.IngresoResult> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post('/ingreso-diario/')
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      return { kind: 'ok', ingreso: data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async getIngresos(fecha__gt: string, fecha__lt: string): Promise<Types.GetIngresosResult> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/ingreso-diario/', { fecha__gt, fecha__lt })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      return { kind: 'ok', ingresos: data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async getEstadisticas(): Promise<Types.GetEstadisticasUsuarioResult> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/estadistica-usuario/')

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response
      return { kind: 'ok', estadisticas: data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async getPublicaciones(page: number): Promise<Types.GetPublicacionesPaginatedResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/publicaciones/?page=${page}`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      const nextPage = data.next ? 1 + page : null

      return { kind: 'ok', publicaciones: data.results, nextCursor: nextPage }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async getPublicacion(id: number): Promise<Types.GetPublicacionResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/publicaciones/${id}/`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', publicacion: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async getBeneficios(page: number): Promise<Types.GetBeneficiosResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/beneficios/?page=${page}`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      const nextPage = data.next ? 1 + page : null

      return { kind: 'ok', beneficios: data.results, nextCursor: nextPage }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async getCanjesUsuario(page: number): Promise<Types.GetCanjesResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/canjes/?page=${page}`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      const nextPage = data.next ? 1 + page : null
      console.log(data)
      return { kind: 'ok', canjes: data.results, nextCursor: nextPage }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async getNotificaciones(): Promise<Types.GetNotificacionesResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/notificaciones/`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', notificaciones: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }
  async canjearBeneficio(beneficio_id: number): Promise<Types.PostDefault> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post('/beneficio-canjear/', { beneficio_id })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
  async agregarConsulta(consulta: Types.Consulta): Promise<Types.PostDefault> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post('/consultas/', { ...consulta })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
  async setNotificacionVista(id: number): Promise<Types.GetNotificacionResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/notificaciones/${id}/`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      console.log(data)
      return { kind: 'ok', notificacion: data.result }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async setRespuestaClase(clase_id: number, opciones_ids: { id: number }[]): Promise<Types.PostRespuesta> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.post(`/clase/${clase_id}/respuesta/`, {
        opciones_elegidas: [...opciones_ids],
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const { data } = response

      return { kind: 'ok', resultado: data }
    } catch (e) {
      // __DEV__ && console.tron.log(e.message)
      //@ts-ignore
      return { kind: 'bad-data' }
    }
  }

  async setDevice(userId: string, pushToken: string, activo: boolean): Promise<Types.PostDefault> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(`/set-device/`, {
        user_id: userId,
        push_token: pushToken,
        activo: activo,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
  async postLogout(device_id: string): Promise<Types.PostDefault> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(`/logout/`, { device_id })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
