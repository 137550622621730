import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { withEnvironment } from '../extensions/with-environment'
import { UsuarioModel, UsuarioSnapshot } from '../user/user'

/**
 * Login Store
 */
export const LoginStoreModel = types
  .model('LoginStore')
  .extend(withEnvironment)
  .props({
    usuario: types.maybeNull(UsuarioModel),
  })
  .actions((self) => ({
    setApiToken: (token?: string) => {
      const api = self.environment.api.apisauce
      if (!!self.usuario && !!token) {
        self.usuario.token = token
        api.setHeader('Authorization', 'Token ' + token)
      } else {
        api.deleteHeader('Authorization')
      }
    },
  }))
  .actions((self) => ({
    setUsuario: (user: UsuarioSnapshot | null) => {
      if (!user) {
        self.usuario = null
        return
      }
      self.usuario = { ...user }
    },
    logout: () => {
      self.usuario = null
      self.environment.api.apisauce.deleteHeader('Authorization')
    },
  }))
  .actions((self) => ({
    //api actions
    getLogin: async (username: string, password: string) => {
      const api = self.environment.api
      const result = await api.getLogin(username, password)
      if (result.kind ==='ok') {
        self.setUsuario(result.usuario)
        result.usuario.token && self.setApiToken(result.usuario.token)
      }
      return result
    },
  }))
type LoginStoreType = Instance<typeof LoginStoreModel>
export interface LoginStore extends LoginStoreType {}
type LoginStoreSnapshotType = SnapshotOut<typeof LoginStoreModel>
export interface LoginStoreSnapshot extends LoginStoreSnapshotType {}
export const createLoginStoreDefaultModel = () => types.optional(LoginStoreModel, {})
