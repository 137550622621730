import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { RUTAS } from '../../constant'
import { useIsMovil } from '../../helpers'
import { Categoria } from '../../services/api'
interface Props {
  categoria: Categoria
}
export const CategoriaCardContainer: React.FC<Props> = (props) => {
  const { categoria } = props
  const history = useHistory()
  const isMovil = useIsMovil()
  const [bgStyle, setbgStyle] = useState<React.CSSProperties>({})
  const [textStyle, setTextStyle] = useState<React.CSSProperties>({})

  useEffect(() => {
    const style: React.CSSProperties = {}
    const styleText: React.CSSProperties = {}
    if (categoria.fondo) {
      style.backgroundImage = `url('${categoria.fondo}')`
      style.backgroundRepeat = 'no-repeat'
      style.backgroundSize = 'cover'
    }
    // if (categoria.fondo_titulo_color) {
    //   styleText.color = categoria.fondo_titulo_color
    // }
    setbgStyle(style)
    setTextStyle(styleText)
  }, [])

  return (
    <div>
      <div
        key={categoria.id}
        className={`rounded-xl aspect-square  p-2 bg-red  cursor-pointer mb-3`}
        onClick={() => history.push(RUTAS.CATEGORIA(categoria.id))}
        style={bgStyle}
      ></div>
      <h2 className="line-clamp-1 text-ellipsis text-center" style={textStyle}>
        {categoria.nombre}
      </h2>
      <div className="text-center">
        <span>{categoria.cantidad_clases} Videos</span>
      </div>
    </div>
  )
}
