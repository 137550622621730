import { IonImg } from '@ionic/react'
import React from 'react'
import { AssetTypes, IMG_ASSET } from './assets'

type IonImgProps = React.ComponentProps<typeof IonImg>

 export interface ImgAssetProps extends IonImgProps {
  selected: AssetTypes
}
export const ImgAsset: React.FC<ImgAssetProps> = (props) => {
  const { selected, ...otherProps } = props
  return <IonImg src={IMG_ASSET[selected]} {...otherProps} />
}
