import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { AiOutlineReload } from 'react-icons/ai'
import { FiBook, FiUser } from 'react-icons/fi'
import { useHistory, useRouteMatch } from 'react-router'
import { CategoriaItemClases, NavHeader, Page } from '../../components'
import { useIsMovil } from '../../helpers'
import { Categoria as CategoraType } from '../../models/categoria/categoria'
import { useCategoria } from '../../models/query-hooks'

export const CategoriaPage: React.FC = observer(() => {
  const match = useRouteMatch()
  // @ts-ignore
  const { isLoading, data: categoria } = useCategoria(match.params.id)
  const [cabeceraStyles, setCabeceraStyles] = useState<React.CSSProperties>({})

  const isMovil = useIsMovil()
  const [Index, setIndex] = useState<'videos' | 'descripcion'>('videos')

  useEffect(() => {
    console.log(categoria)
    const styles: React.CSSProperties = {}
    if (categoria?.fondo_titulo) {
      styles.backgroundImage = `url(${categoria.fondo_titulo})`
      styles.backgroundRepeat = 'no-repeat'
      styles.backgroundSize = 'contain'
      styles.backgroundPosition = 'right bottom'
      styles.backgroundSize = '100%'
    }
    if (categoria?.fondo_titulo_color) {
      styles.backgroundColor = categoria.fondo_titulo_color
    }
    setCabeceraStyles(styles)
  }, [categoria])

  return (
    <Page header={<NavHeader className={isMovil ? 'bg-black text-white' : ''} />}>
      <div className="bg-black rounded-lg" style={cabeceraStyles}>
        <div className="text-white p-5">
          <h1 className="text-white my-9  text-3xl  font-bold" style={categoria?.titulo_color_texto?{color:categoria.titulo_color_texto}:{}}>{categoria?.nombre}</h1>
          <div className="flex flex-col">
            <div className="flex  pb-1">
              <AiOutlineReload className="mr-1" />
              <p className="text-xs  "> Actualización {moment(categoria?.fecha_modificacion).format('l')}</p>
            </div>
            <div className="flex  pb-1">
              <FiBook className="mr-1" />
              <p className="text-xs">{categoria?.cantidad_vistas} vistas</p>
            </div>
            <div className="flex pb-1">
              <FiUser className="mr-1" />
              <p className="text-xs"> Shell Staff</p>
            </div>
          </div>
          <div className="pt-2">
            <span className="font-bold text-xl ">Suma +Puntos</span>
          </div>
        </div>
      </div>
      <div className="flex pt-5  px-5 border-b-[1px]  border-b-secondary/20">
        <div className={`pr-8 pb-5 ${Index === 'videos' ? 'border-b-2' : ''}`}>
          <h1 onClick={() => setIndex('videos')}>VIDEOS </h1>
        </div>
        <div className={`pr-8 pb-5 ${Index === 'descripcion' ? 'border-b-2' : ''}`}>
          <h1 onClick={() => setIndex('descripcion')}>DESCRIPCIÓN </h1>
        </div>
      </div>
      <div className="p-5">
        {Index === 'descripcion' && (
          <>
            <h5>{categoria?.nombre}</h5>
            <span>{categoria?.descripcion}</span>
          </>
        )}
        {Index === 'videos' && (
          <>
            {categoria?.sub_categorias.map((categoria: CategoraType) => (
              <CategoriaItemClases categoria={categoria} key={categoria.id} />
            ))}
          </>
        )}
      </div>
    </Page>
  )
})
