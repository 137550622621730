import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { CategoriaStoreModel } from '../categoria-store/categoria-store'
import { ClaseStoreModel } from '../clase-store/clase-store'
import { withEnvironment } from '../extensions/with-environment'
import { InsigniaStoreModel } from '../insignia-store/insignia-store'
import { LoginStoreModel } from '../login-store/login-store'

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore")
.extend(withEnvironment)
.props({
  categoriaStore: types.optional(CategoriaStoreModel, {} as any),
  claseStore: types.optional(ClaseStoreModel, {} as any), 
  loginStore: types.optional(LoginStoreModel, {} as any),
  insigniaStore: types.optional(InsigniaStoreModel, {} as any),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
