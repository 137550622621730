import React from 'react'
import Modal from 'react-modal'
import './ModalAction.css'

Modal.setAppElement('#root')
interface Props extends React.ComponentProps<typeof Modal> {}

export const ModalAction: React.FC<Props> = (props) => {
  const { className, children, ...rest } = props
  return (
    <Modal
      id="modal-action"
      className={
        'p-8 shadow-lg rounded-lg bg-white  w-3/4 absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2  lg:w-[20vw] ' +
        className
      }
      overlayClassName="modal-action-overlay"
      {...rest}
    >
      {children}
    </Modal>
  )
}
