import { IonButtons, IonContent, IonLabel, IonToolbar } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { BsBell, BsBook, BsChevronLeft, BsDot, BsHouseDoor } from 'react-icons/bs'
import { HiOutlineUserCircle } from 'react-icons/hi'
import { IoMdMenu } from 'react-icons/io'
import { MdDynamicFeed } from 'react-icons/md'
import { RiLogoutBoxLine } from 'react-icons/ri'
import Modal from 'react-modal'
import { useHistory } from 'react-router'
import { RUTAS } from '../../constant'
import { useIsMovil, useLogout } from '../../helpers'
import { useNotificaciones } from '../../models/query-hooks'
import { ImgAsset } from '../ImgAsset/ImgAsset'
import { NotificationTray } from '../NotificationTray/NotificationTray'
import { SideMenu } from '../SideMenu/SideMenu'
import { UserAvatar } from '../UserAvatar'
import './NavHeader.css'

Modal.setAppElement('#root')

interface Props {
  leftButton?: React.ReactNode
  goBackAction?: any
  rightButton?: React.ReactNode
  title?: string
  className?: string
  presets?: 'white'
}
export const NavHeader: React.FC<Props> = (props) => {
  const history = useHistory()
  const [Menu, setMenu] = useState(false)
  const [ShowNotifications, setShowNotifications] = useState(false)
  const { data: notificaciones } = useNotificaciones()
  const [NotificaiconesPendientes, setNotificaiconesPendientes] = useState(false)
  const isMovil = useIsMovil()
  useEffect(() => {
    return () => {
      setMenu(false)
    }
  }, [])
  useEffect(() => {
    if (notificaciones) {
      setNotificaiconesPendientes(notificaciones?.length > 0)
    }
  }, [notificaciones])
  return (
    <>
      <NotificationTray isOpen={ShowNotifications} onRequestClose={() => setShowNotifications(false)} />
      <Modal
        isOpen={Menu}
        onRequestClose={() => setMenu(!Menu)}
        overlayClassName="sidemenu-overlay"
        className="h-[100vh] w-[50vw] bg-white/20 outline-none"
        style={{ content: { background: 'rgb(255 255 255 / 0.2)' } }}
      >
        <SideMenu
          onNavigateExtraAction={() => setMenu(!Menu)}
          header={
            <div className=" relative p-5 px-2 bg-gradient-to-b from-white to to-white/80">
              <div className="cursor-pointer" onClick={() => setMenu(!Menu)}>
                <BsChevronLeft size={20} />
              </div>
              <div className="absolute top-1/2 right-1/2 translate-x-1/2 translate-y-[-50%]">
                <h3>Menu</h3>
              </div>
            </div>
          }
        />
      </Modal>
      <div>
        <div className="relative w-full h-max lg:shadow-lg lg:pb-4">
          <IonToolbar id="nav-header" className={props.presets === 'white' ? 'text-white ' : '' + props.className}>
            <IonButtons slot="start">
              {!props.leftButton && (
                <>
                  {isMovil && (
                    <div
                      className="cursor-pointer"
                      onClick={!props.goBackAction ? () => history.goBack() : props.goBackAction}
                    >
                      <BsChevronLeft size={20} />
                    </div>
                  )}
                  {!isMovil && (
                    <div className="cursor-pointer" onClick={() => history.push(RUTAS.HOME)}>
                      <ImgAsset selected="academiaLogo" className="w-10 h-10" />
                    </div>
                  )}
                </>
              )}
              {!!props.leftButton && <>{props.leftButton}</>}
            </IonButtons>
            <IonButtons slot="primary">
              {!!props.rightButton && <>{props.rightButton}</>}
              {!props.rightButton && (
                <>
                  <div className=" flex items-center">
                    {NotificaiconesPendientes && (
                      <div
                        onClick={() => setShowNotifications(!ShowNotifications)}
                        className="cursor-pointer mr-2 relative"
                      >
                        <BsBell size={22} />
                        <BsDot className="text-red absolute top-[-0.5rem] right-[-0.5rem]" />
                      </div>
                    )}
                    {isMovil && (
                      <div className="cursor-pointer" onClick={() => setMenu(!Menu)}>
                        <IoMdMenu size={28} />
                      </div>
                    )}
                    {!isMovil && (
                      <div className="cursor-pointer ml-4" onClick={() => history.push(RUTAS.PERFIL)}>
                        <UserAvatar className="h-10 w-10" />
                      </div>
                    )}
                  </div>
                </>
              )}
            </IonButtons>
          </IonToolbar>
          {isMovil && (
            <div className="pt-5 absolute top-1/2 right-1/2 translate-x-1/2 translate-y-[-50%]">
              <h3>{props.title}</h3>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
