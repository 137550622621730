import { IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonModal } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import 'swiper/css'
import 'swiper/css/pagination'

import { BsChevronLeft, BsChevronRight, BsX } from 'react-icons/bs'
import { useHistory } from 'react-router'
import { Button, ImgAsset, MenuListItemCard, NavHeader, Page } from '../../components'
import { RUTAS } from '../../constant'
import { Beneficio } from '../../models/beneficio/beneficio'
import { useBeneficios, useCanjearBeneficio, useCurrentUsuario } from '../../models/query-hooks'
import { UsuarioModel } from '../../models/user/user'
import { useIsMovil, useToaster } from '../../helpers'
import { IMG_ASSET } from '../../components/ImgAsset/assets'

export const BeneficiosPage: React.FC = observer(() => {
  const [NextPageNull, setNextPageNull] = useState(false)
  const { data: beneficiosPage, isLoading, fetchNextPage } = useBeneficios()
  const { data: usuario } = useCurrentUsuario()
  useEffect(() => {
    console.log(beneficiosPage)
    if (!beneficiosPage?.pages[beneficiosPage.pages.length - 1].nextCursor) {
      setNextPageNull(true)
    }
  }, [beneficiosPage])

  return (
    <Page header={<NavHeader title="Canjear" />}>
      <div className="p-5">
        <div
          className="bg-yellow  rounded-xl shadow-lg px-6 h-16 lg:h-42 xl:h-56  pb-5 mb-4 "
          style={{
            backgroundImage: `url(${IMG_ASSET.beneficiosBanner})`,
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
          }}
        ></div>
        <div className=" mx-auto w-full  ">
          <IonList className="mt-4">
            <IonInfiniteScroll
              position="bottom"
              onIonInfinite={() => {
                if (beneficiosPage?.pages[beneficiosPage.pages.length - 1].nextCursor) {
                  fetchNextPage()
                  return
                }
              }}
            >
              <div>
                <MenuListItemCard className="my-5">
                  <div className="mb-8">
                    <span className="text-black text-xl ">Puntos acumulados: {usuario?.puntos}</span>
                  </div>
                  <div className="flex gap-12">
                    <div>
                      <span className="text-sm">
                        {!usuario?.first_name && !usuario?.last_name && <>{usuario?.username}</>}
                        {usuario?.first_name} {usuario?.last_name}
                      </span>
                    </div>
                    <div>
                      <span>VÁLIDO DIC {new Date().getFullYear()}</span>
                    </div>
                  </div>
                </MenuListItemCard>
              </div>
              {beneficiosPage?.pages.map((beneficios, index) => (
                <React.Fragment key={index}>
                  {beneficios.beneficios.map((beneficio) => (
                    <React.Fragment key={beneficio.id}>
                      <BeneficioCard beneficio={beneficio} />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
              {!NextPageNull && <IonInfiniteScrollContent loadingSpinner={'crescent'}></IonInfiniteScrollContent>}
            </IonInfiniteScroll>
          </IonList>
        </div>
      </div>
    </Page>
  )
})

interface BeneficioCardProps {
  beneficio: Beneficio
}
const BeneficioCard: React.FC<BeneficioCardProps> = (props) => {
  const [ModalCanje, setModalCanje] = useState(false)
  const { mutate: canjearBeneficio } = useCanjearBeneficio()
  const [Success, setSuccess] = useState(false)
  const history = useHistory()
  const { beneficio } = props
  const { data: usuario } = useCurrentUsuario()
  const isMovil = useIsMovil()
  const toast = useToaster()

  const canjearHandler = () => {
    canjearBeneficio(
      { beneficio_id: beneficio.id },
      {
        onSuccess: () => {
          history.push(RUTAS.BENEFICIOS)
          setSuccess(true)
        },
      },
    )
  }

  return (
    <>
      <IonModal isOpen={ModalCanje}>
        {!Success && (
          <>
            <div className="p-5 pb-10 flex flex-col h-full">
              {isMovil && (
                <NavHeader
                  leftButton={
                    <>
                      <div onClick={() => setModalCanje(false)}>
                        <BsChevronLeft size={20} />
                      </div>
                    </>
                  }
                  title={'Carrito'}
                />
              )}
              {!isMovil && (
                <div className="flex p-4">
                  <div className="cursor-pointer " onClick={() => setModalCanje(false)}>
                    <BsX size={24} />
                  </div>
                </div>
              )}
              <div className="flex items-center mb-8 mt-5">
                <div>
                  <IonImg className="rounded-xl w-20 h-20  mr-3 bg-yellow" src={beneficio.imagen} />
                </div>
                <div>
                  <p>
                    <strong>{beneficio.nombre}</strong>
                    <br />
                    <span>{beneficio.puntos} ePoints</span>
                  </p>
                </div>
              </div>
              <MenuListItemCard className="flex items-center ">
                <div>
                  <span> Forma de Canje</span>
                </div>
                <div className="flex-1 flex justify-end items-center gap-1">
                  ePoint <BsChevronRight size={15} />
                </div>
              </MenuListItemCard>
              <div className="flex-1 flex flex-col justify-end ">
                <div className="flex justify-between pt-3 border-t-2 border-t-secondary/50 mb-5">
                  <div>
                    <strong> Total</strong>
                  </div>
                  <div>
                    <strong>ePoints {beneficio.puntos}</strong>
                  </div>
                </div>
                <Button onClick={canjearHandler}>
                  <strong>Canjear Ahora</strong>
                </Button>
              </div>
            </div>
          </>
        )}
        {Success && (
          <div className="relative h-full w-full">
            <div className="absolute bottom-0 w-full  bg-gradient-to-b from-white to-yellow "> </div>
            <div className="h-full p-5 flex flex-col items-center justify-center">
              <div className="pb-16">
                <ImgAsset className="w-32 h-32" selected="academiaLogo" />
              </div>
              <div className="flex flex-col items-center gap-6">
                <h2>¡Felicidades!</h2>
                <p className="text-center my-10">
                  ¡Has canjeado correctamente tus puntos!
                  <br />
                  El equipo se pondrá en contacto contigo para hacerte la entrega de tu obsequio.
                </p>
              </div>
              <div className="flex-1 w-full flex items-center px-5">
                <Button
                  className="w-full font-bold"
                  onClick={() => {
                    setModalCanje(false)
                    setSuccess(false)
                    history.push(RUTAS.PERFIL)
                  }}
                >
                  Finalizar
                </Button>
              </div>
            </div>
          </div>
        )}
      </IonModal>
      <MenuListItemCard
        onClick={() => {
          if (!!usuario?.puntos && beneficio.puntos > usuario?.puntos) {
            toast(`Le faltan ${beneficio.puntos - usuario.puntos} puntos para canjear.`)
            return
          }
          setModalCanje(true)
        }}
        className={`flex items-center  justify-center cursor-pointer mb-5  ${
          !!usuario?.puntos && beneficio.puntos > usuario?.puntos && 'bg-secondary/20'
        }`}
      >
        <div className="flex-1 border-r-2  mr-3 pr-3 flex  lg:justify-end">
          <div className="flex content-end items-center">
            <IonImg className=" rounded-xl bg-yellow h-14 w-14 lg:h-20 lg:w-20" src={beneficio.imagen} />
          </div>
          <div className=" lg:text-right lg:w-32 ">
            <div className="px-1">
              <div>
                <p className="text-xs font-bold line-clamp-2   overflow-hidden text-ellipsis">{beneficio.nombre}</p>
              </div>
              <span className="text-[0.625rem]  leading-3">Canje directo</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <span className="text-xl lg:text-3xl font-bold ">{beneficio.puntos} </span>
          <span className="text-xs "> Pts.</span>
        </div>
      </MenuListItemCard>
    </>
  )
}
