import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { CategoriaModel, CategoriaSnapshot } from '../categoria/categoria'
import { withEnvironment } from '../extensions/with-environment'

/**
 * Categoria Store
 */
export const CategoriaStoreModel = types
  .model('CategoriaStore')
  .extend(withEnvironment)
  .props({
    categorias: types.optional(types.array(CategoriaModel), []),
    categoriaSeleccionada: types.maybeNull(CategoriaModel),
  })
  .actions((self) => ({
    // saveCategorias: (categoriaSnapshots: CategoriaSnapshot[]) => {
    //   self.categorias.replace(categoriaSnapshots)
    // },
    // setCategoriaSeleccionada: (categoria: CategoriaSnapshot | null) => {
    //   if (!!categoria ) {
    //     self.categoriaSeleccionada = { ...categoria }
    //   } else {
    //     self.categoriaSeleccionada = null
    //   }
    // },
    // clear: () => {
    //   self.categoriaSeleccionada = null
    //   self.categorias.replace([])
    // },
    // setCategorias:(categorias: CategoriaSnapshot[])=>{ 
    //   self.categorias.replace(categorias)
    // }
  }))
  .actions((self) => ({
    // api actions
    getCategorias: async () => {
      const categoriaApi = self.environment.api
      const result = await categoriaApi.getCategorias()

      if (result.kind === 'ok') {
        //@ts-ignore
        self.saveCategorias(result.categorias)
      } else {
        // __DEV__ && console.tron.log(result.kind)
        console.log(result.kind)
      }
    },
  }))

type CategoriaStoreType = Instance<typeof CategoriaStoreModel>
export interface CategoriaStore extends CategoriaStoreType {}
type CategoriaStoreSnapshotType = SnapshotOut<typeof CategoriaStoreModel>
export interface CategoriaStoreSnapshot extends CategoriaStoreSnapshotType {}
export const createCategoriaStoreDefaultModel = () => types.optional(CategoriaStoreModel, {})
