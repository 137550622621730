import { IonButton, IonHeader, IonMenuButton, IonNavLink, IonTitle, IonToolbar } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router'

import { RUTAS } from '../../constant'
import { useIsMovil, useLogout } from '../../helpers'
import { useStores } from '../../models/root-store/root-store-context'

export const Header: React.FC = observer(() => {
  // const { isLoading: loadingUsuario, data: usuario } = useUsuario(loginStore?.usuario?.id)
  const history = useHistory()
  const movil = useIsMovil()
  const cerrarSesion = useLogout()

  return (
    <>
      {!movil && (
        <IonHeader id="header-web" className="flex  pb-5">
          <IonTitle className=" p-5 cursor-pointer" onClick={() => history.push(RUTAS.HOME)}>
            Protagonistas
          </IonTitle>
          <div className="flex-1 flex align-center">
            <nav className="flex  items-end">
              <IonNavLink onClick={() => history.push(RUTAS.FEED)} className="mx-5 cursor-pointer">
                Muro
              </IonNavLink>
              <IonNavLink onClick={() => history.push(RUTAS.HOME)} className="mx-5 cursor-pointer">
                Campus
              </IonNavLink>
              <IonNavLink onClick={() => history.push(RUTAS.BENEFICIOS)} className="mx-5 cursor-pointer">
                Beneficios
              </IonNavLink>
              <IonNavLink onClick={() => history.push(RUTAS.PERFIL)} className="mx-5 cursor-pointer">
                Perfil
              </IonNavLink>
            </nav>
          </div>
          <div className="py-3 pr-3">
            <IonButton onClick={cerrarSesion}>
              Cerrar Sesión
            </IonButton>
          </div>
        </IonHeader>
      )}
      {movil && (
        <IonHeader>
          <IonToolbar>
            <IonButton slot="start" fill="clear">
              <IonMenuButton />
            </IonButton>
            <IonTitle className="cursor-pointer" onClick={() => history.push(RUTAS.HOME)}>
              Protagonistas
            </IonTitle>
          </IonToolbar>
        </IonHeader>
      )}
    </>
  )
})
