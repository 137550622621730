import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { RUTAS } from '../../constant'
import { useBuscarClases, useCurrentUsuario } from '../../models/query-hooks'
import { ClaseListContainer } from '../ClaseListContainer/ClaseListContainer'
import { SearchBar } from '../SearchBar/SearchBar'

export const Buscador: React.FC = () => {
  const history = useHistory()
  const { data: usuario } = useCurrentUsuario()
  const [Buscar, setBuscar] = useState<string | null>()
  const { data: clases, isLoading } = useBuscarClases(Buscar)

  const onSumit = () => {
    const busqueda = !!Buscar ? `?buscar=${Buscar}` : ''
    history.push(RUTAS.CLASES + busqueda)
  }

  return (
    <>
      <div className="pt-5 px-5">
        <div className="rounded-lg bg-white/50 p-5 shadow-lg ">
          <div className="flex ">
            <div className="w-6 h-6 mr-2 bg-yellow rounded-full"> </div>
            <h2>Hola, {usuario?.first_name ? usuario.first_name : usuario?.username} </h2>
          </div>
          <p>Encontrá los cursos que te gustaría ver.</p>
          <div className="pt-5">
            <SearchBar onSubmitSearch={onSumit} value={Buscar} onIonChange={(e) => setBuscar(e.detail.value ?? '')} />
          </div>
        </div>
        <div className="relative inline-block  z-50">
          {!isLoading && clases && clases?.length > 0 && (
            <div className="block absolute w-[19rem] lg:w-[56rem] max-h-96 overflow-y-scroll p-5 bg-white shadow-lg rounded-lg">
              {clases.map((clase) => (
                <ClaseListContainer key={clase.id} clase={clase} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
