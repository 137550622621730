import { IonImg } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { AssetTypes } from './ImgAsset/assets'
import { ImgAsset } from './ImgAsset/ImgAsset'

type ImgProps = React.ComponentProps<typeof IonImg>

interface Props extends ImgProps {
  alternativeAsset: AssetTypes
  src?: any
}

export const Thumbnail: React.FC<Props> = observer((props) => {
  const { alternativeAsset, src, ...rest } = props
  return <>{!!src ? <IonImg src={src} {...rest} /> : <ImgAsset selected={alternativeAsset} {...rest} />}</>
})
