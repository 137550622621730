import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export const OpcionModel = types.model('Opcion').props({
  id: types.integer,
  respuesta: types.maybeNull(types.string),
  pregunta_id: types.integer,
  correcto: types.boolean,
})

export interface Opcion extends OpcionType {}
export type OpcionType = SnapshotIn<typeof OpcionModel>
export const createRespuestaDefaultModel = () => types.optional(OpcionModel, {} as any)

export const PreguntaModel = types.model('Pregunta').props({
  id: types.integer,
  pregunta: types.string,
  opciones: types.array(OpcionModel),
})

export type PreguntaType = SnapshotIn<typeof PreguntaModel>
export const createPreguntaDefaultModel = () => types.optional(PreguntaModel, {} as any)
