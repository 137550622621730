import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import React, { useEffect, useMemo, useState } from 'react'
import { ClaseListContainer, NavHeader, Page, SearchBar, Spinner } from '../../components'
import { useSearchQuery } from '../../helpers'
import { Clase } from '../../models/clase/clase'
import { useClases } from '../../models/query-hooks'

export const Clases: React.FC = (props) => {
  const [CategoriaId, setCategoriaId] = useState<number | null>()
  const query = useSearchQuery()
  const [Busqueda, setBusqueda] = useState<string>('')
  const [Clases, setClases] = useState<Clase[]>([])
  const { data: clasesPages, fetchNextPage, isLoading } = useClases(CategoriaId)
  const clasesFiltradas = useMemo(
    () =>
      Clases?.filter((clase) => {
        if (!Busqueda) return true
        const titulo = clase.nombre.toLowerCase()
        return titulo.includes(Busqueda.toLowerCase())
      }),
    [Clases, Busqueda],
  )

  useEffect(() => {
    const categoriaId = query.get('c')
    const busqueda = query.get('buscar')
    if (categoriaId) {
      setCategoriaId(CategoriaId)
    } else {
      setCategoriaId(null)
    }

    if (busqueda) {
      setBusqueda(busqueda)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    const clases: Clase[] = []

    clasesPages?.pages.forEach((page) => {
      clases.push(...page.clases)
    })

    setClases([...clases])
  }, [clasesPages])

  return (
    <Page header={<NavHeader title={Busqueda ? `Busqueda: ${Busqueda}` : 'Clases'} />}>
      <div className="flex flex-col pt-5">
        <div className="p-4 rounded-lg shadow-lg mb-6">
          <SearchBar value={Busqueda} onIonChange={(e) => setBusqueda(e.detail.value ?? '')} />
        </div>
        <IonInfiniteScroll
          // disabled={!clases?.pages[clases.pages.length - 1].nextCursor}
          onIonInfinite={() => {
            if (clasesPages?.pages[clasesPages.pages.length - 1].nextCursor) {
              fetchNextPage()
              return
            }
          }}
        >
          <Spinner show={isLoading} />
          {clasesFiltradas.map((clase) => (
            <ClaseListContainer key={clase.id} clase={clase} />
          ))}
          {!!clasesPages?.pages[clasesPages.pages.length - 1].nextCursor && (
            <IonInfiniteScrollContent loadingSpinner={'crescent'}></IonInfiniteScrollContent>
          )}
        </IonInfiniteScroll>
      </div>
    </Page>
  )
}
